import { AuthURLs } from '../../URLs/AuthUrls';
import classes from './ResetPassForm.module.css';
import { useRef, useState } from 'react';

const ResetPassForm = () => {
  const [res, setRes] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const newPassRef = useRef();
  const confirmPassRef = useRef();

  const restPassAPI = async () => {
    setIsSubmitting(true);
    const searchParams = new URL(window.location.href).search;
    const urlConfirm = searchParams.split('&');
    const email = urlConfirm[0].replace('?email=', '');
    const token = urlConfirm[1].replace('token=', '');

    const data = {
      email: email,
      token: token,
      password: newPassRef.current.value,
      confirmPassword: confirmPassRef.current.value,
    };

    const response = await fetch(AuthURLs.resetPass, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(data),
    });

    const resData = await response.json();
    setRes(resData);
    setIsSubmitting(false);
  };

  const submitHandle = (e) => {
    e.preventDefault();
    restPassAPI();
  };

  return (
    <div className={classes.rset_pass_container}>
      <form method="post" className={classes.rset_pass} onSubmit={submitHandle}>
        <div className={classes.rset_inputDiv}>
          <label htmlFor="resetPass">New Password</label>
          <input
            type="password"
            id="resetPass"
            name="resetPass"
            ref={newPassRef}
            required
          ></input>
        </div>

        <div className={classes.rset_inputDiv}>
          <label htmlFor="resetConfirmPass">Confirm Password</label>
          <input
            type="password"
            id="resetConfirmPass"
            name="resetConfirmPass"
            ref={confirmPassRef}
            required
          ></input>
        </div>

        <button className={classes.rset_button} type="submit">
          {isSubmitting ? 'Please Wait...' : 'Submit'}
        </button>
        {res && (
          <p style={{ fontSize: '1.2rem', fontWeight: '600' }}>{res.message}</p>
        )}
      </form>
    </div>
  );
};
export default ResetPassForm;
