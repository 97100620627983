import classes from './OrderSummary.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { AppointmentsUrls } from '../../URLs/PublicUrls';
import { LoginVisibilityActions } from '../../store/loginVisibility-slice';
import { CartActions } from '../../store/cart-slice';

const OrderSummary = (props) => {
  const state = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const startTime = new Date(state.appointmentDateTime).getTime();

  const endTime = state.items.reduce((prev, curr) => {
    return prev + (curr.hours * 60 + curr.min) * 60 * 1000;
  }, startTime);

  const serviceItems = state.items.map((item) => {
    return {
      serviceId: item.id,
      startTime: new Date(state.appointmentDateTime),
      endTime: new Date(endTime),
      price: item.price,
      duration: `${item.hours}Hrs-${item.min}Min`,
    };
  });

  const data = {
    appointmentId: '00000000-0000-0000-0000-000000000000',
    appointmentNo: 'TEMP',
    appointmentDateTime: state.appointmentDateTime,
    startDateTime: new Date(state.appointmentDateTime),
    endDateTime: new Date(endTime),
    duration: endTime - startTime,
    bookingStatus: 'PENDING',
    paymentStatus: 'PENDING',
    modeOfPayment: 'OFFLINE',
    paymentPlace: 'VENUE',
    subTotal: state.totalAmount,
    discount: 0,
    total: state.totalAmount,
    tax: 0,
    review: 'PENDING',
    rating: 0,
    branchEmployeeId: state.employee.employeeId,
    createdDate: new Date(Date.now()),
    updatedDate: new Date(Date.now()),
    employeeName: state.employee.employeeName,
    businessName: state.businessName,
    businessEmail: state.businessEmail,
    appointmentServices: serviceItems,
    userId:
      localStorage.getItem('userID') || '00000000-0000-0000-0000-000000000000',
  };

  const sendData = async (data) => {
    const response = await fetch(AppointmentsUrls.insertAppointment, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const resData = await await response.json();
    dispatch(
      CartActions.setAppointment({
        type: 'APPOINTMENT-ID',
        appointmentID: resData.data,
      })
    );
  };

  const bookingHandle = () => {
    const token = localStorage.getItem('token');

    if (token) {
      sendData(data);
      props.onBook();
    } else {
      dispatch(LoginVisibilityActions.toggleVisibility());
    }
  };

  return (
    <div className={classes.order}>
      <div className={classes.oder_head}>
        <p>Order Summary</p>
      </div>
      <div className={classes.order_body_L}>
        <div className={classes.order_body_L_child}>
          <p>Appointment Date</p>
          <p>
            {new Date(state.appointmentDateTime).toLocaleDateString('en-US', {
              weekday: 'short',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </p>
        </div>
        <div className={classes.order_body_L_child}>
          <p>Appointment Time</p>
          <p>
            {new Date(state.appointmentDateTime).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </p>
        </div>
      </div>
      <div className={classes.order_body_R}>
        {state.items.map((item, i) => (
          <div key={i} className={classes.order_body_R_details}>
            <div className={classes.order_body_R_child}>
              <p>Service Name</p>
              <p>{item.name}</p>
            </div>
            <div className={classes.order_body_R_child}>
              <p>Duration</p>
              <p>{`${item.hours}Hr : ${item.min}Min`}</p>
            </div>
            <div className={classes.order_body_R_child}>
              <p>Price</p>
              <p>&#8358; {item.price}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.order_body_bottom}>
        <div className={classes.order_body_bottom_child}>
          <p>Payment Amount</p>
          <p>&#8358; {state.totalAmount}</p>
        </div>
        <button className={classes.orderSummaryBtn} onClick={bookingHandle}>
          Confirm Booking
        </button>
      </div>
      <div className={classes.order_body}></div>
    </div>
  );
};
export default OrderSummary;
