import { createSlice } from '@reduxjs/toolkit';

const CustomerValidationSlice = createSlice({
  name: 'customerValidation',
  initialState: {
    signUp: {
      name: {
        inputTouched: false,
        error: null,
      },
      register: {
        status: null,
        message: null,
      },
    },
    manualRedirect: false,
  },
  reducers: {
    touched(state, action) {
      if (action.payload.formType === 'signup') {
        state.signUp.name.inputTouched = true;
        if (action.payload.value === 0)
          state.signUp.name.error = 'Please fill up your name.';
      }
    },

    registeration(state, action) {
      state.signUp.register.status = action.payload.status;
      state.signUp.register.message = action.payload.message;
    },

    setRedirect(state) {
      state.manualRedirect = true;
    },
  },
});

export default CustomerValidationSlice;
export const CustomerValidationActions = CustomerValidationSlice.actions;
