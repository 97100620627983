import classes from './BlogListItem.module.css';
import { Link } from 'react-router-dom';

const BlogListItem = (props) => {
  const { item } = props;

  return (
    <li className={classes.blogList_head_list_Item}>
      <Link to={`/blogs/${item.url}`} className={classes.blogList_item_details}>
        <p className={classes.blogList_item_details_title}>{item.title}</p>
        <p className={classes.blogList_item_details_date}>
          {new Date(item.createdDate).toDateString()}
        </p>
        <img
          className={classes.blogList_item_details_img}
          src="/assets/images/general/avatar.jpg"
          alt="blog"
        ></img>

        <div
          className={classes.blogList_item_details_desc}
          dangerouslySetInnerHTML={{ __html: item.description.slice(0, 300) }}
        />
        <strong>+Read more</strong>
      </Link>
    </li>
  );
};
export default BlogListItem;
