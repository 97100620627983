import { Form, useNavigation, useActionData } from 'react-router-dom';
import classes from './BusinessServices.module.css';
import { useState } from 'react';
import { BusinessUrls } from '../../URLs/PublicUrls';

const BusinessServices = (props) => {
  const [currentEditing, setCurrentEditing] = useState({
    categoryName: '',
    serviceName: '',
    price: '',
  });
  const [method, setMethod] = useState('post');

  const navigation = useNavigation();
  const btnState = navigation.state === 'submitting';

  const actionData = useActionData();

  const { data } = props;

  const EditHandler = (id) => {
    const serviceIndex = data.serviceList.findIndex(
      (item) => item.serviceId === id
    );
    const serviceItem = data.serviceList[serviceIndex];
    setCurrentEditing(serviceItem);
    setMethod('put');
  };

  const deleteHandle = async (id) => {
    const response = await fetch(BusinessUrls.deleteService + id, {
      method: 'DELETE',
      body: { id: id },
    });
    if (response.ok) {
      alert('Delete successful');
      window.location.reload();
    }
  };

  if (actionData && actionData.status === 'success') window.location.reload();
  return (
    <div className={classes.buss_services}>
      <h1>Services</h1>
      <Form method={method} className={classes.bus_srv_inputs}>
        <div className={classes.bus_srv_inputDivs}>
          <label htmlFor="bussServCategory">Category</label>
          <select name="bussServCategory" id="bussServCategory">
            <option value="none">Please select</option>
            {data.categoryList.map((category) => (
              <option
                value={category.categoryId}
                key={category.categoryId}
                selected={category.categoryName === currentEditing.categoryName}
              >
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.bus_srv_inputDivs}>
          <label htmlFor="bussServServiceName">Service Name</label>
          <input
            defaultValue={currentEditing.serviceName}
            type="text"
            id="bussServServiceName"
            name="bussServServiceName"
          ></input>
        </div>
        <div className={classes.bus_srv_inputDivs}>
          <label htmlFor="bussServPrice">Price</label>
          <input
            defaultValue={currentEditing.price}
            type="text"
            id="bussServPrice"
            name="bussServPrice"
          ></input>
        </div>
        <div className={classes.bus_srv_inputDivs}>
          <label htmlFor="bussServDurationHr">Duration Hours</label>
          <input
            defaultValue={currentEditing && currentEditing.durationHours}
            min="0"
            type="number"
            id="bussServDurationHr"
            name="bussServDurationHr"
          ></input>
        </div>
        <div className={classes.bus_srv_inputDivs}>
          <label htmlFor="bussServDurationMin">Duration Minutes</label>
          <input
            defaultValue={currentEditing && currentEditing.durationMinutes}
            type="number"
            min="0"
            id="bussServDurationMin"
            name="bussServDurationMin"
          ></input>
        </div>
        <div className={classes.bus_srv_inputDivs}>
          <label htmlFor="bussServDescription">Description</label>
          <input
            defaultValue={currentEditing && currentEditing.description}
            type="text"
            id="bussServDescription"
            name="bussServDescription"
          ></input>
        </div>
        <div className={classes.bus_srv_inputDivs}>
          <label htmlFor="bussServStatus">Status</label>
          <select
            id="bussServStatus"
            name="bussServStatus"
            defaultValue={
              currentEditing && currentEditing.status ? 'Active' : 'Inactive'
            }
          >
            <option>Active</option>
            <option>Inactive</option>
          </select>
        </div>
        <div className={classes.bus_srv_inputDivs}>
          <input
            type="hidden"
            id="bussServID"
            name="bussServID"
            defaultValue={currentEditing && currentEditing.serviceId}
          ></input>
        </div>
        {actionData && actionData.errors && (
          <p
            className={classes.inputErrorMsg}
          >{`Please fill up: ${actionData.errors.join(', ')}`}</p>
        )}
        <button className={classes.bus_srv_Btn}>
          {btnState ? 'Please Wait...' : 'Submit'}
          <span>{actionData && actionData.message}</span>
        </button>
      </Form>

      <ul className={classes.bus_Srv_ActivList}>
        <li
          className={classes.bus_Srv_ActivList_Item}
          style={{
            fontSize: '1.4rem',
            fontWeight: 700,
            color: '#116d6e',
            padding: '1.5rem',
            boxShadow: '0px 0px 5px rgb(77, 77, 77)',
          }}
        >
          <div className={classes.bus_Srv_ItemDetails}>
            <p>Category</p>
            <p>Service</p>
            <p>Price</p>
            <p>Status</p>
          </div>
          <div className={classes.bus_Srv_ItemActions}>
            <p>Action</p>
          </div>
        </li>
        {data.serviceList.map((service) => (
          <li
            className={classes.bus_Srv_ActivList_Item}
            key={service.serviceId}
          >
            <div className={classes.bus_Srv_ItemDetails}>
              <p>{service.categoryName}</p>
              <p>{service.serviceName}</p>
              <p>&#8358; {service.price}</p>
              <p>{service.status ? 'Active' : 'Inactive'}</p>
            </div>
            <div className={classes.bus_Srv_ItemActions}>
              <p onClick={EditHandler.bind(null, service.serviceId)}>
                <span
                  className={`material-symbols-outlined ${classes.bus_Srv_Edit}`}
                >
                  edit
                </span>
              </p>

              <p onClick={deleteHandle.bind(null, service.serviceId)}>
                <span
                  className={`material-symbols-outlined ${classes.bus_Srv_Del}`}
                >
                  delete
                </span>
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default BusinessServices;
