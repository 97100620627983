import classes from './ReviewItems.module.css';
import RatingsPopulater from '../../utils/RatingsPopulater';
import { useState } from 'react';

const ReviewItems = (props) => {
  const [textFormatted, setTextFormatted] = useState(true);
  const { data } = props;
  const textFormatHandle = () => {
    setTextFormatted(!textFormatted);
  };

  return (
    <div className={classes.reviewItem}>
      <img
        className={classes.reviewItemDP}
        src={data.imageSrc ? data.imageSrc : 'assets/images/general/avatar.jpg'}
        alt="profile"
      ></img>
      {RatingsPopulater(data.rating, '2.3rem', '2.3rem')}
      <p className={classes.reviewItemText}>
        {textFormatted ? data.review.slice(0, 100) : data.review}
        <span onClick={textFormatHandle}>
          {textFormatted ? '+See more' : '-See less'}
        </span>
      </p>
    </div>
  );
};

export default ReviewItems;
