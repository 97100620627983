import { useLoaderData, json, defer, Await } from 'react-router-dom';
import { Suspense } from 'react';
import TopCategories from '../components/homeComponents/TopCategories';
import Hero from '../components/homeComponents/Hero';
import PopularBrands from '../components/homeComponents/PopularBrands';
import {
  CategoriesUrls,
  BranchUrls,
  ServiceUrls,
  TestimonialsUrls,
} from '../URLs/PublicUrls';
import WhyUs from '../components/homeComponents/WhyUs';
import Reviews from '../components/homeComponents/Reviews';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import classes from '../components/homeComponents/Hero.module.css';
import HeroSearches from '../components/homeComponents/HeroSearches';
import DataLoadingFunc from '../utils/DataLoadingFunc';

const Home = () => {
  const { categories, branches, searches, reviews } = useLoaderData();
  const reload = localStorage.getItem('homeReload');
  if (reload) {
    localStorage.removeItem('homeReload');
    window.location.reload();
  }

  return (
    <>
      <div className={classes.hero}>
        <Hero />
        <Suspense>
          <Await resolve={searches}>
            {(loadedData) => <HeroSearches searches={loadedData} />}
          </Await>
        </Suspense>
      </div>
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={categories}>
          {(loadedData) => <TopCategories categories={loadedData} />}
        </Await>
      </Suspense>

      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={branches}>
          {(loadedData) => <PopularBrands brands={loadedData} />}
        </Await>
      </Suspense>

      <WhyUs />

      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={reviews}>
          {(loadedData) => <Reviews reviews={loadedData} />}
        </Await>
      </Suspense>
    </>
  );
};

const categoriesLoader = async () => {
  return await DataLoadingFunc(
    CategoriesUrls.categories,
    'Could not load search data',
    500
  );
};

const branchesLoader = async () => {
  return await DataLoadingFunc(
    BranchUrls.topBrances,
    'Could not load branches',
    500
  );
};

const homeSearchLoader = async () => {
  const response = await fetch(ServiceUrls.services);
  const response2 = await fetch(BranchUrls.branchByLocation);
  const response3 = await fetch(BranchUrls.branches);

  if (!response.ok && !response2.ok && !response3.ok) {
    throw json({ message: 'Could not load branches', status: 500 });
  } else {
    const resData = await response.json();
    const resData2 = await response2.json();
    const resData3 = await response3.json();

    const saloons = resData3.data.map((item) => {
      return { name: item.businessName, url: item.branchUrl };
    });

    return {
      services: resData.data,
      locations: resData2.data,
      saloons,
    };
  }
};

const reviewsLoader = async () => {
  return await DataLoadingFunc(
    TestimonialsUrls.homeTestimonials,
    'Could not load testimonials',
    500
  );
};

export const loader = () => {
  return defer({
    searches: homeSearchLoader(),
    categories: categoriesLoader(),
    branches: branchesLoader(),
    reviews: reviewsLoader(),
  });
};

export default Home;
