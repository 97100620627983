import classes from './Branch.module.css';
import RatingsPopulater from '../../utils/RatingsPopulater';
import BranchServiceList from './BranchServiceList';
import { useDispatch } from 'react-redux';
import { CartActions } from '../../store/cart-slice';

const Branch = (props) => {
  const data = props.branch[0];

  const dispatch = useDispatch();

  if (
    localStorage.getItem('cartID') &&
    data.branchId !== localStorage.getItem('cartID')
  ) {
    dispatch(CartActions.resetCart());
  }
  localStorage.setItem('cartID', data.branchId);

  return (
    <div className={classes.branch}>
      <div className={classes.branchHero}>
        <img
          className={classes.branchHeroImg}
          src={data.imageSrc}
          alt="Branch Hero"
        ></img>
        <div className={classes.branchIntro}>
          <h1>{data.businessName}</h1>
          <p className={classes.branchIntro_location}>
            <span className="material-symbols-outlined locationIcon">
              location_on
            </span>
            {data.location}
          </p>
          <div className={classes.branchIntroRating}>
            {data.totalRatings === 0
              ? 'New'
              : RatingsPopulater(data.rating, '2rem', '2rem')}
            <span>({data.totalRatings}) Reviews</span>
          </div>
          <a href="#appointmentBookSec" className={classes.branchIntroBookBtn}>
            Book
          </a>
          <a href="#branchReviews" className={classes.branchIntroReviewBtn}>
            Reviews
          </a>
        </div>
      </div>
      <BranchServiceList
        services={data.branchServices}
        timings={data.branchTimings}
        branchId={data.branchId}
        businessName={data.businessName}
      />
      <div id="branchReviews" className={classes.branchReviews}>
        <p>No Reviews!</p>
      </div>
    </div>
  );
};

export default Branch;
