import DataLoadingFunc from '../utils/DataLoadingFunc';
import classes from './FAQ.module.css';
import { faqUrls } from '../URLs/PublicUrls';
import { defer, Await, useLoaderData } from 'react-router-dom';
import { Suspense } from 'react';

const FAQ = () => {
  const { faqs } = useLoaderData();

  const subjects = new Set();
  faqs.forEach((elm) => {
    subjects.add(elm.subjectName);
  });

  return (
    <div className={classes.faq}>
      <h1>Frequently Asked Questions</h1>
      <div className={classes.faq_box}>
        {Array.from(subjects).map((subject) => (
          <ul className={classes.faq_subjects}>
            <h4 className={classes.faq_subject_title}>&#11166; {subject}</h4>
            {faqs.map((item) => {
              let jsx;
              if (item.subjectName === subject)
                jsx = (
                  <li className={classes.faq_sub_item}>
                    <p className={classes.faq_sub_item_que}>
                      <strong>Question: </strong>
                      {item.question}
                    </p>
                    <p className={classes.faq_sub_item_ans}>
                      <strong>Answer: </strong>
                      {item.answer}
                    </p>
                  </li>
                );
              return jsx;
            })}
          </ul>
        ))}
      </div>
    </div>
  );
};
export default FAQ;

export const loader = async () => {
  return defer({
    faqs: await DataLoadingFunc(faqUrls.faqs, 'could not load faqs', 500),
  });
};
