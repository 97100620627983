import { configureStore } from '@reduxjs/toolkit';
import CartSlice from './cart-slice';
import LoginVisibilitySlice from './loginVisibility-slice';
import CustomerValidationSlice from './customerFormValidation';
import BusinessRegisterSlice from './businessRegister-slice';

const store = configureStore({
  reducer: {
    loginVisibility: LoginVisibilitySlice.reducer,
    cart: CartSlice.reducer,
    customerValidation: CustomerValidationSlice.reducer,
    businessRegister: BusinessRegisterSlice.reducer,
  },
});

export default store;
