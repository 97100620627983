import { useState, useEffect } from 'react';
import axios from 'axios';
import classes from './PartnerFees.module.css';
import { useNavigate } from 'react-router';

const PartnerFees = (props) => {
  const { subscriptions, subscriptionsData } = props;
  const navigate = useNavigate();
  const [branchSubscription, setBranchSubscription] = useState('no branch');
  function MakePayment(e, item) {
    if (branchSubscription === 'no branch') {
      navigate(`/auth?mode=register`);
    } else {
      localStorage.setItem('subscriptionItem', JSON.stringify(item));
      window.location.replace(item.paymentLink);
    }
  }

  subscriptions.forEach((elm1) => {
    elm1.features = [];
    subscriptionsData.forEach((elm2) => {
      if (elm1.subscriptionTypeId === elm2.subscriptionTypeId) {
        elm1.features.push(elm2.subscriptionText);
      }
    });
  });

  const applicationAPI = (
    url = 'https://munnywebapi.azurewebsites.net/api/BranchSubscription/'
  ) => {
    return {
      fetchBranchSubscription: () =>
        axios.get(
          url + 'GetByBranch?BranchId=' + localStorage.getItem('branchId')
        ),
    };
  };
  function getBranchDetails() {
    applicationAPI()
      .fetchBranchSubscription()
      .then((res) => setBranchSubscription(res.data.data[0].subscriptionTypeId))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    if (localStorage.getItem('branchId') !== null) {
      getBranchDetails();
    } else {
      setBranchSubscription('no branch');
    }
  }, []);
  return (
    <div className={classes.plans}>
      <h1>Plan that’s right for you</h1>
      <div className={classes.plansBox}>
        {subscriptions.map((item) => (
          <div className={classes.plan} key={item.subscriptionTypeId}>
            <div className={classes.planHeadImg}>
              <span>{item.subscriptionName}</span>
            </div>
            <ul className={classes.planFeatureList}>
              {item.features.map((item2, i) => (
                <li className={classes.planFeature} key={i}>
                  {item2}
                </li>
              ))}
            </ul>
            <p className={classes.subscribeRate}>
              <span>&#36; </span>{item.usdPrice}
            </p>
            {item.subscriptionTypeId === branchSubscription ? (
              <button
                disabled
                className={classes.subscribeBtn}
                onClick={(e) => MakePayment(e, item)}
              >
                Subscribed
              </button>
            ) : branchSubscription === 'no branch' ? (
              <button
                className={classes.subscribeBtn}
                onClick={(e) => MakePayment(e, item)}
              >
                Click here for subscription
              </button>
            ) : (
              <button
                className={classes.subscribeBtn}
                onClick={(e) => MakePayment(e, item)}
              >
                Upgrade
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default PartnerFees;
