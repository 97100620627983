import { Outlet } from 'react-router';
import MainNavigation from '../components/MainNavigation';
import Footer from '../components/Footer';

const Root = () => {
  return (
    <>
      <MainNavigation />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Root;
