import { createSlice } from '@reduxjs/toolkit';

const LoginVisibilitySlice = createSlice({
  name: 'loginVisibility',
  initialState: {
    visibility: false,
  },
  reducers: {
    toggleVisibility(state) {
      state.visibility = !state.visibility;
    },
    loginHider(state) {
      state.visibility = false;
    },
  },
});

export const LoginVisibilityActions = LoginVisibilitySlice.actions;
export default LoginVisibilitySlice;
