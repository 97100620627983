import classes from './BlogList.module.css';
import BlogListItem from './BlogListItem';

const BlogList = (props) => {
  const { list } = props;

  return (
    <div className={classes.blogList}>
      <h1 className={classes.blogList_head}>Explore us!</h1>
      <ul className={classes.blogList_head_list}>
        {list.map((item) => (
          <BlogListItem item={item} key={item.url} />
        ))}
      </ul>
    </div>
  );
};
export default BlogList;
