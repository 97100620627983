import { useLoaderData, defer, Await } from 'react-router-dom';
import AllBranches from '../components/branchComponents/AllBranches';
import { Suspense } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import { quickSearchUrls } from '../URLs/PublicUrls';
import DataLoadingFunc from '../utils/DataLoadingFunc';

const SearchPage = () => {
  const { searchvalue } = useLoaderData();

  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={searchvalue}>
          {(loadedData) => <AllBranches branches={loadedData} />}
        </Await>
      </Suspense>
    </>
  );
};

const searchLoader = async (id) => {
  return await DataLoadingFunc(
    quickSearchUrls.search,
    "Could't Search",
    500,
    id
  );
};

export const loader = ({ request, params }) => {
  const id = params.value;
  return defer({
    searchvalue: searchLoader(id),
  });
};
export default SearchPage;
