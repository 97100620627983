import classes from './PopularBrands.module.css';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const PopularBrands = (props) => {
  const navigate = useNavigate();

  const explorer = () => {
    navigate('/branches');
  };
  return (
    <div className={classes.popbrands}>
      <h1>Popular Brands</h1>
      <p className={classes.brandsBrief}>
        Discover the Coveted Names in Salon Industry: Top Popular Brands for
        Unmatched Beauty
      </p>
      <ul className={classes.popbrandsList}>
        {props.brands.map((brand) => (
          <li className={classes.popbrandsListItem} key={brand.branchUrl}>
            <Link
              to={`/branches/${brand.branchUrl}`}
              className={classes.popbrandsListItemsAnchor}
            >
              <img src={brand.imageSrc} alt="popular brands"></img>
              <p>{brand.businessName}</p>
            </Link>
          </li>
        ))}

        <button className={classes.popbrandsBtn} onClick={explorer}>
          Explore more<span>&#8594;</span>
        </button>
      </ul>
    </div>
  );
};

export default PopularBrands;
