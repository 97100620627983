import { redirect } from 'react-router-dom';

export const action = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('userID');
  localStorage.removeItem('branchId');
  localStorage.removeItem('BranchUrl');
  localStorage.removeItem('Currency');
  localStorage.setItem('homeReload', true);
  return redirect('/');
};
