import classes from './AllBranchItem.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import RatingsPopulater from '../../utils/RatingsPopulater';
import { BranchUrls, ServiceUrls } from '../../URLs/PublicUrls';
import DataLoadingFunc from '../../utils/DataLoadingFunc';

const AllBranchItem = (props) => {
  const [timings, setTimings] = useState();
  const [services, setServices] = useState();
  const [timeVisibility, setTimeVisibility] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const branch = props.branch;

  const navigate = useNavigate();
  const navigateToBranch = () => {
    navigate(`/branches/${branch.branchUrl}`);
  };
  const timeVisibilityHandle = async () => {
    setWaiting(true);
    const timings = await DataLoadingFunc(
      BranchUrls.branchAvailability,
      'Could not load timings',
      500,
      branch.branchId
    );

    const services = await DataLoadingFunc(
      ServiceUrls.serviceByBranch,
      'Could not load services',
      500,
      branch.branchId
    );

    setTimings(timings);
    setServices(services);
    setWaiting(false);
    setTimeVisibility(!timeVisibility);
  };

  return (
    <li className={classes.branchListItem}>
      <div className={classes.itemDetails}>
        <div className={classes.itemDetailsLeft}>
          <img
            className={classes.itemDetailsLeftImg}
            src={branch.imageSrc}
            alt="Branch"
          ></img>
          <div>
            <h4 className={classes.itemDetailsLeftTitle}>
              {branch.businessName}
            </h4>
            <div className={classes.itemDetailsLeftRating}>
              {RatingsPopulater(branch.totalRatings, '2rem', '2rem')}
            </div>
            <p className={classes.itemDetailsLeftLocation}>
              <span className="material-symbols-outlined locationIcon">
                location_on
              </span>
              {branch.address}
            </p>
            <div className={classes.itemDetailsLeftReview}>
              <p className={classes.itemDetailsLeftReviewBlock}>5</p>
              <p className={classes.itemDetailsLeftReviewText}>Reviews</p>
            </div>
          </div>
        </div>
        <div className={classes.itemDetailsRight}>
          <p className={classes.itemDetailsRightVenue}>About Venue</p>
          <p className={classes.itemDetailsRightDesc}>{branch.about}</p>
          <button
            className={classes.itemDetailsRighttimingsBtn}
            onClick={timeVisibilityHandle}
          >
            {waiting ? 'Please Wait...' : 'Timings'}
            <span>
              <img
                className={classes.AllBranchItemdropdown}
                src="/assets/images/general/dropdown.png"
                alt="dropdown"
              ></img>
            </span>
          </button>
          <Link
            to={`/branches/${branch.branchUrl}`}
            className={classes.itemDetailsRightBookBtn}
          >
            Go to venue
          </Link>
        </div>
      </div>
      {timeVisibility && (
        <div className={classes.timingSection}>
          <div className={classes.timingList}>
            <ul>
              {timings.map((time, i) => (
                <li className={classes.timingListItem} key={i}>
                  <p>{time.dayOfWeek}</p>
                  <p>{`${time.openingTime}:00 - ${time.closingTime}:00`}</p>
                  {time.isOpened ? (
                    <p style={{ color: '#019267' }}>Open</p>
                  ) : (
                    <p style={{ color: '#cd1818' }}>Closed</p>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.topServices}>
            <ul className={classes.topServiceslist}>
              <h3 style={{ color: '#116d6e' }}>Popular Services</h3>

              {services.map((service) => (
                <li className={classes.topServicesItem}>
                  <p>{service.serviceName}</p>
                  <p>{`Duration: ${service.durationHours}Hrs:${service.durationMinutes}Min`}</p>
                  <p>&#8358; {service.price}</p>
                  {/* <a href="/abc" className={classes.topServicesSelect}>
                    Select &#8594;
                  </a> */}
                </li>
              ))}
            </ul>
            <button
              className={classes.exploreVenueBtn}
              onClick={navigateToBranch}
            >
              More Services &#8594;
            </button>
          </div>
        </div>
      )}
    </li>
  );
};
export default AllBranchItem;
