import classes from './Reviews.module.css';
import ReviewItems from './ReviewItems';

const Reviews = (props) => {
  return (
    <div className={classes.review}>
      <h1>What people are saying about us?</h1>
      <p className={classes.reviewBrief}>
      Word of Mouth: Hear What People Are Saying About Us!
      </p>
      <div className={classes.reviewItems}>
        {props.reviews.map((review) => (
          <ReviewItems data={review} key={review.testimonialId} />
        ))}
      </div>
    </div>
  );
};

export default Reviews;
