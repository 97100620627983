import { createSlice } from '@reduxjs/toolkit';

const BusinessRegisterSlice = createSlice({
  name: 'businessRegister',
  initialState: {
    name: undefined,
    email: undefined,
    password: undefined,
    mobile: undefined,
    businessProfileImg: null,
  },
  reducers: {
    setData(state, action) {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.mobile = action.payload.mobile;
    },
  },
});

export const BusinessRegisterAction = BusinessRegisterSlice.actions;
export default BusinessRegisterSlice;
