import classes from './LoadingSpinner.module.css';
const LoadingSpinner = () => {
  return (
    <>
      <div className={classes.spinner}>&nbsp;</div>
      <p className={classes.text}>Loading</p>
    </>
  );
};
export default LoadingSpinner;
