const domain = 'https://munnysalon.azurewebsites.net';

export const BranchUrls = {
  topBrances: `${domain}/api/Branch/GetTopActiveBranches`,
  branches: `${domain}/api/Branch/GetActiveBranches`,
  branchByURL: `${domain}/api/Branch/GetByURL/`,
  branchByLocation: `${domain}/api/Branch/Locations`,
  branchByID: `${domain}/api/Branch/GetById/`,
  branchAvailability: `${domain}/api/BranchAvailability/GetByBranch?BranchId=`,
};

export const CategoriesUrls = {
  categories: `${domain}/api/Category/GetByStatus`,
  categoryByUrl: `${domain}/api/Branch/SearchBranches?CategoryURL=`,
  categoryList: `${domain}/api/Category/Get`,
};

export const ServiceUrls = {
  services: `${domain}/api/Service/Search`,
  serviceByBranch: `${domain}/api/Service/GetByBranch?BranchId=`,
};

export const TestimonialsUrls = {
  homeTestimonials: `${domain}/api/Testimonial/GetHomePage`,
};

export const subscriptionsUrls = {
  allPlans: `${domain}/api/SubscriptionType/GetByStatus`,
  allPlansData: `${domain}/api/SubscriptionData/Get`,
  branchSubscription: `${domain}/api/BranchSubscription/`,
};

export const faqUrls = {
  faqs: `${domain}/api/FAQ/GetBySubject`,
};

export const quickSearchUrls = {
  search: `${domain}/api/Branch/SearchBranches?`,
};

export const AppointmentsUrls = {
  availableMonths: `${domain}/api/Branch/MonthlyAvailability`,
  availableTimings: `${domain}/api/Branch/TodaysAvailability`,
  availableEmployees: `${domain}/api/BranchEmployee/GetByBranch`,
  insertAppointment: `${domain}/api/Appointment/Insert`,
  GetAppointmentByUser: `${domain}/api/Appointment/GetByCustomerId`,
  GetAppointmentByStatus: `${domain}/api/Appointment/GetByStatus`,
  GetAppointmentByBranch: `${domain}/api/Appointment/GetByBranch`,
  GetAppointmentById: `${domain}/api/Appointment/GetById`,
  AppointmentByBranchId: `${domain}/api/Appointment/GetByBranchId?BranchId=`,
  completeAppointment: `${domain}/api/Appointment/Completed?AppointmentId=`,
};

export const BlogUrls = {
  allBlogs: `${domain}/api/Blog/Get/`,
  blog: `${domain}/api/Blog/GetByURL?Blogurl=`,
};

export const CustomerUrls = {
  customerProfile: `${domain}/api/Users/GetById`,
  customerUpdateReview: `${domain}/api/Appointment/UpdateReview`,
  customerCancelAppointment: `${domain}/api/Appointment/CancelBooking`,
};

export const BusinessUrls = {
  businessTypes: `${domain}/api/BusinessType/Get`,
  businessUpdate: `${domain}/api/Business/UpdateBusiness`,
  insertService: `${domain}/api/Service/Insert`,
  updateService: `${domain}/api/Service/Update`,
  deleteService: `${domain}/api/Service/Delete?id=`,
  BusinessType: `${domain}/api/BusinessType/Get`,
};
