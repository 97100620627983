import SignUpForms from '../components/forms/SignUpForms';
import {
  useSearchParams,
  json,
  defer,
  useLoaderData,
  Await,
} from 'react-router-dom';
import RegisterForm from '../components/forms/RegisterForm';
import { AuthURLs } from '../URLs/AuthUrls';
import UserConfirmaton from '../components/forms/UserConfirmaton';
import { BusinessUrls } from '../URLs/PublicUrls';
import DataLoadingFunc from '../utils/DataLoadingFunc';
import { Suspense } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const AuthPage = () => {
  const { businessTypes } = useLoaderData();

  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  if (mode === 'signup') return <SignUpForms />;
  if (mode === 'register')
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={businessTypes}>
          {(data) => <RegisterForm businessTypes={data} />}
        </Await>
      </Suspense>
    );
  if (mode === 'account-created') return <UserConfirmaton />;
};
export default AuthPage;

export const action = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get('mode');
  let actionMessage;

  if (mode !== 'signup' && mode !== 'register') {
    throw json({ message: ' unsupported route' }, { status: 422 });
  }
  const data = await request.formData();

  const url = `${new URL(request.url).protocol}//${new URL(request.url).host}`;

  const errors = [];
  const sendData = new FormData();
  const dataFilter = (
    inputName,
    message,
    formAppendName,
    mannualData = undefined
  ) => {
    if (
      data.get(inputName) === '' ||
      data.get(inputName) === undefined ||
      (data.get(inputName) === null && message)
    ) {
      errors.push(message);
    } else {
      sendData.append(formAppendName, data.get(inputName) || mannualData);
    }
  };

  if (mode === 'signup') {
    dataFilter('customerName', 'Name', 'Name');
    dataFilter('customerEmail', 'Email', 'Email');
    dataFilter('customerPass', 'Password', 'Password');
    dataFilter('customerMobile', 'Phone Number', 'PhoneNumber');
    sendData.append('DomainURL', url);

    if (errors[0]) {
      actionMessage = {
        status: 'failed',
        message: `Please fill up: ${errors.join(', ')}`,
      };
      return actionMessage;
    }

    // Check Customer Email
    const chackEmail = await fetch(
      AuthURLs.checkEmail + data.get('customerEmail'),
      {
        method: 'POST',
        body: JSON.stringify({ email: data.get('customerEmail') }),
      }
    );

    const chackEmailData = await chackEmail.json();

    if (!chackEmail.ok) {
      actionMessage = {
        status: 'failed',
        message:
          chackEmailData.message ||
          'Something went wrong. Please try after sometime.',
      };
      return actionMessage;
    }

    //  Customer Registeration
    const response = await fetch(AuthURLs.signUp, {
      method: 'POST',
      body: sendData,
    });

    const resData = await response.json();

    if (!response.ok) {
      actionMessage = {
        status: 'failed',
        message:
          resData.message || 'Something went wrong. Please try after sometime.',
      };
      return actionMessage;
    }

    actionMessage = {
      status: 'success',
      message: resData.message,
    };

    return actionMessage;
  }

  if (mode === 'register') {
    dataFilter('businessName', 'Business Name', 'businessName');
    dataFilter('contactName', 'Contact Name', 'contactName');
    dataFilter('businessTypeID', 'Business Type', 'businessTypeId');
    dataFilter('location', 'Location', 'location');
    dataFilter('landlineNo', 'Landline Number', 'landline');
    dataFilter('mobile', 'Mobile Number', 'mobileNo');
    dataFilter('Email', 'Email', 'email');
    dataFilter('address', 'Address', 'address');
    dataFilter('zipCode', 'Zip Code', 'zipCode');
    dataFilter('city', 'City', 'city');
    dataFilter('country', 'Country', 'country');
    dataFilter('regPass', 'Password', 'password');
    dataFilter('BranchType', null, 'BranchType', 'Main');
    dataFilter(
      'branchId',
      null,
      'BranchType',
      '00000000-0000-0000-0000-000000000000'
    );
    dataFilter('id', null, 'id', '00000000-0000-0000-0000-000000000000');
    dataFilter('branchUrl', null, 'branchUrl', '');
    dataFilter('businessurl', null, 'businessurl', undefined);
    dataFilter('currency', null, 'currency', 'NAIRA');
    dataFilter('googleMapURL', null, 'googleMapURL', '');
    dataFilter('latitude', null, 'latitude', '');
    dataFilter('longitude', null, 'longitude', '');
    dataFilter('totalRatings', null, 'totalRatings', 0);
    dataFilter('rating', null, 'rating', 0);
    dataFilter('about', null, 'about', '');
    dataFilter('status', null, 'status', true);
    dataFilter('BranchImageId', null, 'BranchImageId', '');
    dataFilter('imageSrc', null, 'imageSrc', 'defaultProductImage');
    dataFilter('imageName', null, 'imageName', '');
    dataFilter('imageFile', null, 'imageFile', null);

    if (errors[0]) {
      actionMessage = {
        status: 'failed',
        message: `Please fill up: ${errors.join(', ')}`,
      };
      return actionMessage;
    }

    // Check Business Email
    const chackEmail = await fetch(AuthURLs.checkEmail + data.get('Email'), {
      method: 'POST',
      body: JSON.stringify({ email: data.get('Email') }),
    });

    const chackEmailData = await chackEmail.json();

    if (!chackEmail.ok) {
      actionMessage = {
        status: 'failed',
        message:
          chackEmailData.message ||
          'Something went wrong. Please try after sometime.',
      };
      return actionMessage;
    }

    // Registeration
    const response = await fetch(AuthURLs.registerBusiness, {
      method: 'POST',
      body: sendData,
    });

    const resData = await response.json();
    if (!response.ok) {
      actionMessage = {
        status: 'failed',
        message:
          resData.message || 'Something went wrong. Please try after sometime.',
      };

      return actionMessage;
    }

    actionMessage = {
      status: 'success',
      message: resData.message,
      registerRedirect: true,
    };

    return actionMessage;
  }
};

const businessTypeLoader = async () => {
  return await DataLoadingFunc(
    BusinessUrls.BusinessType,
    'Could not load data',
    500
  );
};

export const loader = () => {
  return defer({
    businessTypes: businessTypeLoader(),
  });
};
