import classes from './RegisterForm.module.css';
import { Form, Link, useNavigation, useActionData } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoginVisibilityActions } from '../../store/loginVisibility-slice';

const RegisterForm = ({ businessTypes }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.businessRegister);

  const responseData = useActionData();

  const navigation = useNavigation();
  const isRegistering = navigation.state === 'submitting';

  const loginVisibilityHandle = () => {
    dispatch(LoginVisibilityActions.toggleVisibility());
  };

  return (
    <div className={classes.register}>
      <Form
        method="post"
        className={classes.inputFieldsBox}
        encType="multipart/form-data"
        noValidate
      >
        <div className={classes.registerHead}>
          <h1>Please Enter Business Details</h1>
          <div
            className={classes.registerHeadlogin}
            onClick={loginVisibilityHandle}
          >
            Already Registered ? Please click here to login
          </div>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="businessType">Business Type</label>
          <select name="businessTypeID" id="businessType">
            {businessTypes.map((item) => (
              <option value={item.businessTypeId} key={item.businessTypeId}>
                {item.businessTypeName}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="businessName">Business Name</label>
          <input
            type="text"
            id="businessName"
            name="businessName"
            required
          ></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="contactName">Contact Name</label>
          <input
            type="text"
            id="contactName"
            name="contactName"
            defaultValue={state.name}
            required
          ></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="Email">Email</label>
          <input
            type="email"
            id="Email"
            name="Email"
            defaultValue={state.email}
            required
          ></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="landlineNo">Landline No</label>
          <input type="text" id="landlineNo" name="landlineNo" required></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="mobile">Mobile</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            defaultValue={state.mobile}
            required
          ></input>
        </div>
        <div className={classes.inputFieldsAddress}>
          <label htmlFor="address">Address</label>
          <input type="text" id="address" name="address" required></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="location">Location/Area</label>
          <input type="text" id="location" name="location" required></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="city">City</label>
          <input type="text" id="city" name="city" required></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="country">Country</label>
          <input type="text" id="country" name="country" required></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="zipCode">Zip Code/Postal Code</label>
          <input type="text" id="zipCode" name="zipCode" required></input>
        </div>
        <div className={classes.inputFields}>
          <label htmlFor="regPass">Password</label>
          <input
            type="password"
            id="regPass"
            name="regPass"
            defaultValue={state.password}
            required
          ></input>
        </div>

        {responseData && (
          <p
            className={classes.regiterError}
            style={{
              color: `${
                responseData.status === 'failed' ? '#cd1818' : '#116d6e'
              }`,
            }}
          >
            {responseData.message}
          </p>
        )}
        {responseData && responseData.registerRedirect && (
          <p className={classes.finalMsg}>
            Please login and go to 'My Account'.
          </p>
        )}

        <button className={classes.regiterBtn}>
          {isRegistering ? 'Please wait..' : 'Register'}
        </button>

        <Link to="/auth?mode=signup" className={classes.regiterCancelBtn}>
          Cancel
        </Link>
      </Form>
    </div>
  );
};
export default RegisterForm;
