import classes from './MainNavigation.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LoginForm from './forms/LoginForm';
import { LoginVisibilityActions } from '../store/loginVisibility-slice';

const MainNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const directToHomeHandle = () => {
    navigate('/');
  };

  const loginVisibility = useSelector(
    (state) => state.loginVisibility.visibility
  );

  const loginVisibilityHandle = () => {
    dispatch(LoginVisibilityActions.toggleVisibility());
  };

  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  let myAccLink;

  if (role === 'Customer') myAccLink = '/customer-account?user=profile';
  if (role === 'Business')
    myAccLink = '/business-account?user=business-details';

  return (
    <>
      <div id="TopJump">
        <nav className={classes.mainNav}>
          <img
            src="/assets/images/general/logo.png"
            alt="logo"
            className={classes.logo}
            onClick={directToHomeHandle}
          ></img>
          <div className={classes.menuListBox}>
            <ul className={classes.menuList}>
              <li className={classes.menuListItem}>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.activNav} ${classes.menuListItemAnchor}`
                      : classes.menuListItemAnchor
                  }
                >
                  <span className="material-symbols-outlined nav_symbols">
                    home
                  </span>
                  Home
                </NavLink>
              </li>
              <li className={classes.menuListItem}>
                <NavLink
                  to="/branches"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.activNav} ${classes.menuListItemAnchor}`
                      : classes.menuListItemAnchor
                  }
                >
                  <span className="material-symbols-outlined nav_symbols">
                    storefront
                  </span>
                  Salons
                </NavLink>
              </li>
              <li className={classes.menuListItem}>
                <NavLink
                  to="/blogs"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.activNav} ${classes.menuListItemAnchor}`
                      : classes.menuListItemAnchor
                  }
                >
                  <span className="material-symbols-outlined nav_symbols">
                    rss_feed
                  </span>
                  Blogs
                </NavLink>
              </li>
              <li className={classes.menuListItem}>
                <NavLink
                  to="/partner-fees"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.activNav} ${classes.menuListItemAnchor}`
                      : classes.menuListItemAnchor
                  }
                >
                  <span className="material-symbols-outlined nav_symbols">
                    payments
                  </span>
                  Partner Fees
                </NavLink>
              </li>
              {token && (
                <>
                  <li className={classes.menuListItem}>
                    <NavLink
                      to={myAccLink}
                      className={({ isActive }) =>
                        isActive
                          ? `${classes.activNav} ${classes.menuListItemAnchor}`
                          : classes.menuListItemAnchor
                      }
                    >
                      <span className="material-symbols-outlined nav_symbols">
                        person
                      </span>
                      My Account
                    </NavLink>
                  </li>
                </>
              )}
              {!token && (
                <>
                  <li
                    className={classes.menuListItem}
                    onClick={loginVisibilityHandle}
                  >
                    <div
                      className={classes.menuListItemAnchor}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="material-symbols-outlined nav_symbols">
                        login
                      </span>
                      Login
                    </div>
                  </li>
                  <li className={classes.menuListItem}>
                    <a
                      href="/auth?mode=signup"
                      className={classes.menuListItemAnchor}
                      style={{
                        borderRadius: '10px',
                        padding: '1rem',
                        backgroundColor: '#116d6e',
                        color: '#fff',
                      }}
                    >
                      <span className="material-symbols-outlined">badge</span>
                      Signup
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
        <div className={classes.miniNav}>
          <input
            id="miniNavigation"
            type="checkbox"
            className={classes.miniNavCheck}
          ></input>

          <label htmlFor="miniNavigation" className={classes.miniNavChkLabel}>
            <span>&nbsp;</span>
          </label>
          <div className={classes.miniNavBackground}></div>
          <ul className={classes.miniMenu}>
            <li className={classes.miniMenuList}>
              <a href="/" className={classes.miniMenuListAnchor}>
                <span className="material-symbols-outlined nav_symbols">
                  home
                </span>
                Home
              </a>
            </li>
            <li className={classes.miniMenuList}>
              <a href="/branches" className={classes.miniMenuListAnchor}>
                <span className="material-symbols-outlined nav_symbols">
                  storefront
                </span>
                Salons
              </a>
            </li>
            <li className={classes.miniMenuList}>
              <a href="/blogs" className={classes.miniMenuListAnchor}>
                <span className="material-symbols-outlined nav_symbols">
                  rss_feed
                </span>
                Blogs
              </a>
            </li>
            <li className={classes.miniMenuList}>
              <a href="/partner-fees" className={classes.miniMenuListAnchor}>
                <span className="material-symbols-outlined nav_symbols">
                  payments
                </span>
                Partner Fees
              </a>
            </li>
            {token && (
              <li className={classes.miniMenuList}>
                <a href={myAccLink} className={classes.miniMenuListAnchor}>
                  <span className="material-symbols-outlined nav_symbols">
                    person
                  </span>
                  My Account
                </a>
              </li>
            )}
            {!token && (
              <>
                <li
                  className={classes.miniMenuList}
                  onClick={loginVisibilityHandle}
                >
                  <div className={classes.miniMenuListAnchor}>
                    <span className="material-symbols-outlined nav_symbols">
                      login
                    </span>
                    Login
                  </div>
                </li>
                <li className={classes.miniMenuList}>
                  <a
                    href="/auth?mode=signup"
                    className={classes.miniMenuListAnchor}
                  >
                    <span className="material-symbols-outlined">badge</span>
                    Signup
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className={classes.miniNavLogo} onClick={directToHomeHandle}>
          <img src="/assets/images/general/logo.png" alt="logo"></img>
        </div>
      </div>
      {loginVisibility && <LoginForm />}
    </>
  );
};

export default MainNavigation;
