import Appointments from '../components/appointments/Appointments';
import { AppointmentsUrls, BranchUrls } from '../URLs/PublicUrls';
import { defer, Await, useLoaderData, json } from 'react-router-dom';
import { Suspense } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import DataLoadingFunc from '../utils/DataLoadingFunc';

const AppointmentPage = () => {
  const { availablity } = useLoaderData();
  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={availablity}>
          {(loadedData) => <Appointments availablity={loadedData} />}
        </Await>
      </Suspense>
    </>
  );
};
export default AppointmentPage;

export const AppointmentDateLoader = async (branchID) => {
  const date = new Date(Date.now());
  const response = await fetch(
    AppointmentsUrls.availableMonths +
      `?BranchId=${branchID}&AppointmentDate=${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`
  );

  const branch = await DataLoadingFunc(
    BranchUrls.branchByID,
    'Could not load branch',
    500,
    branchID
  );

  if (!response.ok || !response) {
    throw json({ message: 'Could not load dates or branch', status: 500 });
  } else {
    const resData = await response.json();
    return { schedule: resData.data, branchUrl: branch.branchUrl };
  }
};

export const loader = ({ params }) => {
  const { branchID } = params;
  return defer({
    availablity: AppointmentDateLoader(branchID),
  });
};
