import classes from './LoginForm.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { LoginVisibilityActions } from '../../store/loginVisibility-slice';
import { useRef } from 'react';
import { AuthURLs } from '../../URLs/AuthUrls';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const [logging, setLogging] = useState(false);
  const [res, setRes] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [inputError, setInputError] = useState([]);
  const manualRedirect = useSelector(
    (state) => state.customerValidation.manualRedirect
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userNameRef = useRef();
  const passRef = useRef();
  const forgotEmailRef = useRef();

  const loginHider = () => {
    dispatch(LoginVisibilityActions.loginHider());
  };

  const propagationHandle = (e) => {
    e.stopPropagation();
  };

  const submitHandle = async (e) => {
    e.preventDefault();
    setLogging(true);
    const data = new FormData();
    const formErrors = [];
    const dataFilter = (inputValue, errorInputName, formAppendName) => {
      if (inputValue === '') {
        formErrors.push(errorInputName);
      } else {
        data.append(formAppendName, inputValue);
      }
    };

    const { protocol } = new URL(window.location.href);
    const { host } = new URL(window.location.href);

    if (forgotPass) {
      dataFilter(forgotEmailRef.current.value, 'Email', 'Email');
      data.append('DomainURL', `${protocol}//${host}`);
    } else {
      dataFilter(userNameRef.current.value, 'Email', 'Username');
      dataFilter(passRef.current.value, 'Password', 'Password');
    }

    setInputError(formErrors);
    if (formErrors[0]) {
      setLogging(false);
      return;
    }

    const postURL = forgotPass
      ? AuthURLs.sendEmail4ResetPass +
        `?Email=${
          forgotEmailRef.current.value
        }&DomainURL=${`${protocol}//${host}`}`
      : AuthURLs.login;

    const response = await fetch(postURL, {
      method: 'POST',
      body: data,
    });

    const resData = await response.json();

    if (!response.ok) {
      setLogging(false);
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('userID');
      dispatch(LoginVisibilityActions.loginHider());
      return alert(resData.message);
    }

    setRes(resData.message);
    if (!forgotPass) {
      localStorage.setItem('token', resData.token);
      localStorage.setItem('role', resData.role);
      localStorage.setItem('userID', resData.userId);
    }
    if (resData.branchId) localStorage.setItem('branchId', resData.branchId);
    if (manualRedirect) navigate('/');
    // localStorage.setItem('expiration', resData.expiration);
    setLogging(false);
    if (!forgotPass) dispatch(LoginVisibilityActions.loginHider());
  };

  const resetEmailHandle = () => {
    setInputError([]);
    setForgotPass(!forgotPass);
  };

  const navigateToSignUp = () => {
    dispatch(LoginVisibilityActions.loginHider());
    navigate('/auth?mode=signup');
  };

  return (
    <div className={classes.login} onClick={loginHider}>
      <form
        encType="multipart/form-data"
        className={classes.loginForm}
        onClick={propagationHandle}
        onSubmit={submitHandle}
      >
        {!forgotPass && (
          <>
            <div className={classes.loginClose} onClick={loginHider}>
              Close
            </div>
            <h1>Login</h1>
            <input
              className={classes.loginInputs}
              type="email"
              id="userName"
              placeholder="Email"
              ref={userNameRef}
            ></input>
            <input
              ref={passRef}
              className={classes.loginInputs}
              type="password"
              id="loginPass"
              placeholder="Password"
            ></input>
          </>
        )}
        {forgotPass && (
          <input
            className={classes.loginInputs}
            type="email"
            id="forgotEmail"
            placeholder="Email"
            ref={forgotEmailRef}
          ></input>
        )}
        {inputError[0] && (
          <p
            className={classes.loginErrors}
          >{`Please fill up: ${inputError.join(', ')}`}</p>
        )}
        <button className={classes.loginBtns} type="submit">
          {logging ? 'Please wait...' : `${forgotPass ? 'Reset' : 'Submit'}`}
        </button>
        {!forgotPass && (
          <p
            className={classes.login_forgotPass_btn}
            onClick={resetEmailHandle}
          >
            Forgot Password?
          </p>
        )}
        {forgotPass && (
          <p
            className={classes.login_forgotPass_btn}
            onClick={resetEmailHandle}
          >
            Cancel
          </p>
        )}
        {forgotPass && <p style={{ fontSize: '1.2rem' }}>{res}</p>}
        <button className={classes.login_signUp} onClick={navigateToSignUp}>
          <p>
            Click here to <strong>SIGNUP!</strong>
          </p>
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
