import classes from './Thankyou.module.css';
import { useSelector } from 'react-redux';

const Thankyou = () => {
  const state = useSelector((state) => state.cart);

  return (
    <div className={classes.thankyou}>
      <div className={classes.thankyou_head}>
        <img src="/assets/images/general/thank-you.png" alt="thank you"></img>
        <h1>Thank You</h1>
        <p>Your Booking has been confirmed</p>
      </div>
      <ul className={classes.thankyou_details}>
        <li>
          <div className={classes.thankyou_details_item}>
            <p>Appointment Number</p>
            <p>{state.appointmentID}</p>
          </div>
        </li>
        <li>
          <div className={classes.thankyou_details_item}>
            <p>Service provider</p>
            <p>{state.businessName}</p>
          </div>
        </li>
        <li>
          <div className={classes.thankyou_details_item}>
            <p>Employee Name</p>
            <p>{state.employee.employeeName}</p>
          </div>
        </li>
        <li>
          <ul>
            {state.items.map((item, i) => (
              <div className={classes.thankyou_details_item} key={i}>
                <p>{`${i + 1}.`} Service Name</p>
                <p>{item.name}</p>
              </div>
            ))}
          </ul>
        </li>
        <li>
          <div className={classes.thankyou_details_item}>
            <p>Appointment Date</p>
            <p>
              {new Date(state.appointmentDateTime).toLocaleDateString('en-US', {
                weekday: 'short',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </p>
          </div>
        </li>
        <li>
          <div className={classes.thankyou_details_item}>
            <p>Appointment Time</p>
            <p>
              {new Date(state.appointmentDateTime).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </p>
          </div>
        </li>
        <li>
          <div className={classes.thankyou_details_item}>
            <p>Payment Amount</p>
            <p>&#8358; {state.totalAmount}</p>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default Thankyou;
