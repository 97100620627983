import PartnerFees from '../components/PartnerFees';
import DataLoadingFunc from '../utils/DataLoadingFunc';
import { defer, Await, useLoaderData } from 'react-router-dom';
import { Suspense } from 'react';
import { subscriptionsUrls } from '../URLs/PublicUrls';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const SubscriptionPage = () => {
  const { data } = useLoaderData();
  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <Await
          resolve={Promise.all([
            data.subscriptions,
            data.subscriptionsData,
          ]).then((res) => res)}
        >
          {(res) => {
            const [subscriptions, subscriptionsData] = res;
            return (
              <PartnerFees
                subscriptions={subscriptions}
                subscriptionsData={subscriptionsData}
              />
            );
          }}
        </Await>
      </Suspense>
    </>
  );
};

const subscriptions = async () => {
  return DataLoadingFunc(
    subscriptionsUrls.allPlans,
    "Couldn't load subscriptions",
    500
  );
};

const subscriptionsData = async () => {
  return DataLoadingFunc(
    subscriptionsUrls.allPlansData,
    "Couldn't load subscriptions data",
    500
  );
};

export const loader = () => {
  return defer({
    data: {
      subscriptions: subscriptions(),
      subscriptionsData: subscriptionsData(),
    },
  });
};
export default SubscriptionPage;
