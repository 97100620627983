import classes from './BranchServiceListItem.module.css';
import { useState } from 'react';
import BranchServiceListISubtem from './BranchServiceListISubtem';

const BranchServiceListItem = (props) => {
  const [visible, setVisible] = useState(false);
  const { data } = props;

  const dropdownHandle = () => {
    setVisible(!visible);
  };

  return (
    <>
      <li className={classes.serviceListItem} onClick={dropdownHandle}>
        <img
          className={classes.serviceListItemIcon}
          src={data.imageSrc}
          alt="listIcon"
        ></img>
        <p className={classes.serviceListItemTitle}>{data.categoryName}</p>
        <p className={classes.serviceListItemSubs}>{`${data.services.length} ${
          data.services.length > 1 ? 'Services' : 'service'
        }`}</p>
        <img
          className={classes.dropdown}
          src="/assets/images/general/dropdown.png"
          alt="dropdown"
        ></img>
      </li>
      {visible && (
        <ul className={classes.subService}>
          {data.services.map((service) => (
            <BranchServiceListISubtem
              service={service}
              key={service.serviceId}
            />
          ))}
        </ul>
      )}
    </>
  );
};
export default BranchServiceListItem;
