import classes from './CustomerAppointments.module.css';

import CustomerAppointmentsItem from './CustomerAppointmentsItem';

const CustomerAppointments = (props) => {
  return (
    <div className={classes.custAcc_Appoint}>
      <h1>My Appointments</h1>

      <ul className={classes.custAcc_Appoint_List}>
        {props.data &&
          props.data.map((cd, index) => (
            <CustomerAppointmentsItem
              cd={cd}
              index={index}
              key={cd.appointmentId}
            />
          ))}
        {!props.data && <p>No appointments.</p>}
      </ul>
    </div>
  );
};
export default CustomerAppointments;
