import { json } from 'react-router-dom';

const DataLoadingFunc = async (
  url,
  errMessage,
  errStatus,
  paramName = null
) => {
  let response;
  if (paramName) {
    response = await fetch(url + paramName);
  } else {
    response = await fetch(url);
  }
  if (!response.ok) {
    throw json({ message: errMessage, status: errStatus });
  } else {
    const resData = await response.json();
    return resData.data;
  }
};

export default DataLoadingFunc;
