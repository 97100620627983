import BusinessAccWrap from '../components/accounts/BusinessAccWrap';
import { useSearchParams } from 'react-router-dom';
import BusinessChangePass from '../components/accounts/BusinessChangePass';
import BusinessAppointments from '../components/accounts/BusinessAppointments';
import BusinessDetails from '../components/accounts/BusinessDetails';
import DataLoadingFunc from '../utils/DataLoadingFunc';
import { Await, defer, useLoaderData } from 'react-router-dom';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import { Suspense } from 'react';
import { AuthURLs } from '../URLs/AuthUrls';
import {
  AppointmentsUrls,
  BranchUrls,
  BusinessUrls,
  CategoriesUrls,
  ServiceUrls,
} from '../URLs/PublicUrls';
import BusinessServices from '../components/accounts/BusinessServices';

const BusinessAccountPage = () => {
  const { appointments, businessDetails, serviceList } = useLoaderData();

  const [searchParams] = useSearchParams();
  const mode = searchParams.get('user');

  if (mode === 'business-details')
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={businessDetails}>
          {(loadedData) => (
            <BusinessAccWrap>
              <BusinessDetails data={loadedData} />
            </BusinessAccWrap>
          )}
        </Await>
      </Suspense>
    );

  if (mode === 'services')
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={serviceList}>
          {(loadedData) => (
            <BusinessAccWrap>
              <BusinessServices data={loadedData} />
            </BusinessAccWrap>
          )}
        </Await>
      </Suspense>
    );

  if (mode === 'change-password')
    return (
      <BusinessAccWrap>
        <BusinessChangePass />
      </BusinessAccWrap>
    );

  if (mode === 'appointments')
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={appointments}>
          {(loadedData) => (
            <BusinessAccWrap>
              <BusinessAppointments data={loadedData} />
            </BusinessAccWrap>
          )}
        </Await>
      </Suspense>
    );
};
export default BusinessAccountPage;

const appointmentLoader = async (id) => {
  return await DataLoadingFunc(
    AppointmentsUrls.AppointmentByBranchId + id,
    'Failed loading appointment data',
    500
  );
};

const businessDetailsLoader = async (id) => {
  const businessTypes = await DataLoadingFunc(
    BusinessUrls.businessTypes,
    'Failed loading business types',
    500
  );
  const businessDetails = await DataLoadingFunc(
    BranchUrls.branchByID + id,
    'Failed loading appointment data',
    500
  );

  localStorage.setItem('BusinessId', businessDetails.businessId);
  localStorage.setItem('BranchUrl', businessDetails.branchUrl);
  localStorage.setItem('Currency', businessDetails.currency);
  return { ...{ businessTypes: businessTypes }, ...businessDetails };
};

const serviceList = async (id) => {
  const categoryList = await DataLoadingFunc(
    CategoriesUrls.categoryList,
    'Failed loading category list',
    500
  );

  const serviceList = await DataLoadingFunc(
    ServiceUrls.serviceByBranch,
    'Failed loading category list',
    500,
    id
  );

  return { ...{ categoryList: categoryList }, ...{ serviceList: serviceList } };
};

export const loader = () => {
  const id = localStorage.getItem('branchId');
  return defer({
    appointments: appointmentLoader(id),
    businessDetails: businessDetailsLoader(id),
    serviceList: serviceList(id),
  });
};

// Actions
export const action = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get('user');
  const inputData = await request.formData();
  let actionMessage;
  const userID = localStorage.getItem('userID');
  const branchId = localStorage.getItem('branchId');
  const sendData = new FormData();
  const errors = [];

  const dataFilter = (
    inputName,
    message,
    formAppendName,
    mannualData = undefined
  ) => {
    if (
      inputData.get(inputName) === '' ||
      inputData.get(inputName) === undefined ||
      (inputData.get(inputName) === null && message)
    ) {
      errors.push(message);
    } else {
      sendData.append(formAppendName, inputData.get(inputName) || mannualData);
    }
  };

  if (mode === 'business-details') {
    dataFilter(
      'BusinessId',
      null,
      'BusinessId',
      localStorage.getItem('BusinessId')
    );
    dataFilter('businessName', 'Business Name', 'BusinessName');
    dataFilter('businessName', null, 'Id', userID);
    dataFilter('BranchId', null, 'BranchId', branchId);
    dataFilter(
      'BranchUrl',
      null,
      'BranchUrl',
      localStorage.getItem('BranchUrl')
    );
    dataFilter('contactName', 'Contact Name', 'ContactName');
    dataFilter('businessLandline', 'Landline', 'Landline');
    dataFilter('businessPhone', 'Phone Number', 'MobileNo');
    dataFilter('businessEmail', 'Email', 'Email');
    dataFilter('businessType', 'Business Type', 'BusinessTypeId');
    dataFilter('businessAddress', 'Address', 'Address');
    dataFilter('businessMap', 'Google Map URL', 'GoogleMapURL');
    dataFilter('businessLatitude', 'Latitude', 'Latitude');
    dataFilter('businessLongitude', 'Longitude', 'Longitude');
    dataFilter('businessLocation', 'Location', 'Location');
    dataFilter('businessZip', 'Zip Code', 'ZipCode');
    dataFilter('businessCity', 'City', 'City');
    dataFilter('businessCountry', 'Country', 'Country');
    dataFilter('businessAbout', 'About', 'About');
    dataFilter('businessCurrency', 'Currency', 'Currency');
    dataFilter('businessTotalRating', 'Total Ratings', 'TotalRatings');
    dataFilter('businessRating', 'Rating', 'Rating');
    dataFilter('businessStatus', 'Status', 'Status');
    sendData.append('ImageFile', inputData.get('businessImage'));
    sendData.append('ImageName', inputData.get('businessImage').name);
    sendData.append('branchImageId', '0e8416a7-fa16-48f9-95e4-3567424ce9b8');
    sendData.append('branchType', undefined);
    sendData.append('businessurl', undefined);

    if (errors[0]) {
      actionMessage = {
        status: 'failed',
        errors: errors,
      };

      return actionMessage;
    }

    const response = await fetch(BusinessUrls.businessUpdate, {
      method: 'PUT',
      body: sendData,
    });

    const resData = await response.json();
    if (!response.ok) {
      actionMessage = {
        status: 'failed',
        message: resData.message,
      };

      return actionMessage;
    }

    actionMessage = {
      status: 'success',
      message: resData.message,
    };
    return actionMessage;
  }

  if (mode === 'services') {
    dataFilter('branchId', null, 'branchId', localStorage.getItem('branchId'));
    dataFilter('bussServServiceName', 'Service Name', 'serviceName');
    dataFilter('bussServDescription', 'Description', 'description');
    dataFilter('bussServCategory', 'Category', 'categoryId');
    dataFilter('bussServDurationHr', 'Duration-Hours', 'durationHours');
    dataFilter('bussServDurationMin', 'Duration-Minutes', 'durationMinutes');
    dataFilter('bussServPrice', 'Price', 'price');
    sendData.append(
      'status',
      inputData.get('bussServStatus') === 'Active' ? true : false
    );

    if (errors[0]) {
      actionMessage = {
        status: 'failed',
        errors: errors,
      };

      return actionMessage;
    }

    if (request.method === 'PUT')
      sendData.append('serviceId', inputData.get('bussServID'));

    const response = await fetch(
      request.method === 'POST'
        ? BusinessUrls.insertService
        : BusinessUrls.updateService,
      {
        method: request.method,
        body: sendData,
      }
    );

    const resData = await response.json();

    if (!response.ok) {
      actionMessage = {
        status: 'failed',
        message: resData.message || 'Please recheck data filled.',
      };

      return actionMessage;
    }

    actionMessage = {
      status: 'success',
      message: resData.message,
      forceReload: request.method === 'PUT',
    };

    return actionMessage;
  }

  if (mode === 'change-password') {
    if (
      inputData.get('businessConfirmPass') !== inputData.get('businessNewPass')
    ) {
      actionMessage = {
        status: 'failed',
        message: 'Reconfirm your password',
      };

      return actionMessage;
    }

    dataFilter('businessOldPass', 'Old Password', 'oldPassword');
    dataFilter('businessNewPass', 'New Password', 'newPassword');
    dataFilter('businessConfirmPass', 'Confirm Password', 'confirmPassword');
    sendData.append('id', userID);

    if (errors[0]) {
      actionMessage = {
        status: 'failed',
        errors: errors,
      };

      return actionMessage;
    }

    const response = await fetch(
      AuthURLs.customerChangePass +
        `?id=${userID}&oldPassword=${inputData.get(
          'businessOldPass'
        )}&newPassword=${inputData.get('businessNewPass')}`,
      {
        method: 'POST',
        body: sendData,
      }
    );

    const resData = await response.json();
    if (!response.ok) {
      actionMessage = {
        status: 'failed',
        message: resData.message,
      };

      return actionMessage;
    }

    actionMessage = {
      status: 'success',
      message: resData.message,
    };

    return actionMessage;
  }
};
