import { Link } from 'react-router-dom';
import classes from './Footer.module.css';

const Footer = () => {
  return (
    <div className={classes.footer}>
      <section className={classes.section1}>
        <div className={classes.sec1Items}>
          <img src="/assets/images/general/secure.png" alt="secure"></img>
          <h4>100% Secure Payments</h4>
          <p>Moving your card details to a much more secured place.</p>
        </div>
        <div className={classes.sec1Items}>
          <img src="/assets/images/general/offer2.png" alt="offer2"></img>
          <h4>Best Offers</h4>
          <p>100% Payment Protection. Easy Return Policy.</p>
        </div>
        <div className={classes.sec1Items}>
          <img src="/assets/images/general/24x7.png" alt="24x7"></img>
          <h4>24X7 Support</h4>
          <p>Get the best offers from top brands.</p>
        </div>
        <div className={classes.sec1Items}>
          <img src="/assets/images/general/trust2.png" alt="trust2"></img>
          <h4>Trust pay</h4>
          <p>We're here to help. Have a query and need help ?</p>
        </div>
      </section>
      <section className={classes.section2}>
        <div className={classes.payAndSubs}>
          <div className={classes.payDiv}>
            <p>Payment methods:</p>
            <div className={classes.paymentImagesBox}>
              <img
                className={classes.paymentImages}
                src="/assets/images/general/visaPay.png"
                alt="payment method"
              ></img>
              <img
                className={classes.paymentImages}
                src="/assets/images/general/payPal.png"
                alt="payment method"
              ></img>
              <img
                className={classes.paymentImages}
                src="/assets/images/general/masterPay.png"
                alt="payment method"
              ></img>
              <img
                className={classes.paymentImages}
                src="/assets/images/general/americanPay.png"
                alt="payment method"
              ></img>
            </div>
          </div>
          <div className={classes.subscribeDiv}>
            <p>Subscribe</p>
            <div className={classes.subscribeDivInput}>
              <input
                id="homeSubscribe"
                type="text"
                placeholder="Please enter email"
              ></input>
              <label htmlFor="homeSubscribe">Subscribe</label>
            </div>
          </div>
          <div className={classes.keepTouchDiv}>
            <p>Keep in touch</p>
            <div className={classes.keepTouchImgBox}>
              <Link
                to={'https://www.facebook.com/munnyfindshq/'}
                target="_blank"
              >
                <img
                  className={classes.keepTouchImg}
                  src="/assets/images/general/facebook.png"
                  alt="facebook"
                ></img>
              </Link>
              <Link to={'https://twitter.com/MunnyfindsHq'} target="_blank">
                <img
                  className={classes.keepTouchImg}
                  src="/assets/images/general/twitter.png"
                  alt="twitter"
                ></img>
              </Link>
              <Link
                to={'https://www.instagram.com/munnyfinds/'}
                target="_blank"
              >
                <img
                  className={classes.keepTouchImg}
                  src="/assets/images/general/insta.png"
                  alt="insta"
                ></img>
              </Link>
              <Link
                to={'https://www.instagram.com/munnyfinds/'}
                target="_blank"
              >
                <img
                  className={classes.keepTouchImg}
                  src="/assets/images/general/threads.png"
                  alt="insta"
                ></img>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.legals}>
          <ul className={classes.footLinks}>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/contactus">Contact</Link>
            </li>
            <li>
              <a
                href="https://munnyfinds.com/staticfiles/Munnyfinds-Partner-Terms-of-Business.pdf"
                rel="noreferrer"
                target="_blank"
              >
                Partner Terms
              </a>
            </li>
            <li>
              <a
                href="https://munnyfinds.com/staticfiles/Terms-and-Condition.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Terms of use
              </a>
            </li>
            <li>
              <a
                href="https://munnyfinds.com/staticfiles/Privacy-Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
          <p>
            Copyright <span>&#169;</span> 2023 <strong>Munny Finds</strong>. All
            Rights Reserved
          </p>
        </div>
      </section>
      <a href="#TopJump" className={classes.footer_Jumper}>
        <p>&#42779;</p>
      </a>
    </div>
  );
};
export default Footer;
