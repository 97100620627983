import classes from './SignUpForms.module.css';
import {
  Link,
  Form,
  useNavigation,
  useActionData,
  useNavigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CustomerValidationActions } from '../../store/customerFormValidation';
import { BusinessRegisterAction } from '../../store/businessRegister-slice';
import { useRef, useState } from 'react';

const SignUpForms = () => {
  const actionMessage = useActionData();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();

  let error = null;
  let success = null;
  if (actionMessage && actionMessage.status === 'failed')
    error = actionMessage.message;
  if (actionMessage && actionMessage.status === 'success')
    success = actionMessage.message;

  const navigation = useNavigation();
  const signingUp = navigation.state === 'submitting';

  const NameFocusHandle = (e) => {
    dispatch(
      CustomerValidationActions.touched({
        formType: 'signup',
      })
    );
  };
  const NameBlurHandle = (e) => {};

  const businessNameRef = useRef();
  const businessEmailRef = useRef();
  const businessPassRef = useRef();
  const businessMobileRef = useRef();

  const registerHandle = () => {
    if (
      businessNameRef.current.value === '' ||
      businessEmailRef.current.value === '' ||
      businessPassRef.current.value === '' ||
      businessMobileRef.current.value === ''
    ) {
      setErrorMsg('Please fill up all above inputs.');
    } else {
      setErrorMsg(null);
      dispatch(
        BusinessRegisterAction.setData({
          name: businessNameRef.current.value,
          email: businessEmailRef.current.value,
          password: businessPassRef.current.value,
          mobile: businessMobileRef.current.value,
        })
      );
      navigate('/auth?mode=register');
    }
  };

  return (
    <div className={classes.signup}>
      <Form
        method="post"
        encType="multipart/form-data"
        className={classes.signupCust}
      >
        <h1>Customer Signup</h1>
        <div className={classes.nameInput}>
          <input
            className={classes.signupInputs}
            type="text"
            id="customerName"
            placeholder="Name"
            name="customerName"
            onFocus={NameFocusHandle}
            onBlur={NameBlurHandle}
          ></input>
          <span className={classes.validationErrors}></span>
        </div>
        <div className={classes.nameInput}>
          <input
            className={classes.signupInputs}
            type="email"
            id="customerEmail"
            placeholder="Email"
            name="customerEmail"
          ></input>
          <span className={classes.validationErrors}></span>
        </div>
        <div className={classes.nameInput}>
          <input
            className={classes.signupInputs}
            type="password"
            id="customerPass"
            placeholder="Create Password"
            name="customerPass"
          ></input>
          <span className={classes.validationErrors}></span>
        </div>
        <div className={classes.nameInput}>
          <input
            className={classes.signupInputs}
            type="text"
            id="customerMobile"
            placeholder="Mobile number"
            name="customerMobile"
          ></input>
          <span className={classes.validationErrors}></span>
        </div>
        {error && (
          <div className={classes.errorBox}>
            <p className={classes.signingUpError}>{error}</p>
          </div>
        )}
        {success && (
          <div className={classes.successBox}>
            <p className={classes.signingUpSuccess}>{`${success}`}</p>
          </div>
        )}
        <button className={classes.signupBtns}>
          {signingUp ? 'Please wait...' : 'Register'}
        </button>
      </Form>

      <div className={classes.signupBusiness}>
        <h1>Business Signup</h1>
        <input
          className={classes.signupInputs}
          type="text"
          id="businessName"
          placeholder="Name"
          ref={businessNameRef}
          required
        ></input>
        <input
          className={classes.signupInputs}
          type="email"
          id="businessEmail"
          placeholder="Email"
          ref={businessEmailRef}
          required
        ></input>
        <input
          className={classes.signupInputs}
          type="password"
          id="businessPass"
          placeholder="Create Password"
          ref={businessPassRef}
          required
        ></input>
        <input
          className={classes.signupInputs}
          type="text"
          id="businessMobile"
          placeholder="Mobile number"
          ref={businessMobileRef}
          required
        ></input>
        {errorMsg && (
          <p style={{ color: 'red', fontSize: '1.3rem' }}>{errorMsg}</p>
        )}
        <button className={classes.signupBtns} onClick={registerHandle}>
          Next
        </button>
      </div>
    </div>
  );
};

export default SignUpForms;
