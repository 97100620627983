import { useRouteError } from 'react-router';
import MainNavigation from '../components/MainNavigation';

const ErrorPage = () => {
  const error = useRouteError();

  let title = 'An Error Occurred';
  let message = 'Something went wrong!';

  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = 'Not Found';
    message = 'Could not find page.';
  }
  return (
    <>
      <MainNavigation />
      <div className="errorPage">
        <h1>{title}</h1>
        <p>{message}</p>
      </div>
    </>
  );
};

export default ErrorPage;
