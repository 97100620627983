import classes from './BusinessAppointments.module.css';
import BusinessAppointmentsItem from './BusinessAppointmentsItem';
const BusinessAppointments = (props) => {
  return (
    <div className={classes.business_Appoint}>
      <h1>My Appointments</h1>
      <ul className={classes.business_Appoint_List}>
        {props.data.map((cd, index) => (
          <BusinessAppointmentsItem
            cd={cd}
            index={index}
            key={cd.appointmentId}
          />
        ))}
      </ul>
    </div>
  );
};
export default BusinessAppointments;
