import { CartActions } from '../../store/cart-slice';
import classes from './BranchServiceList.module.css';
import BranchServiceListItem from './BranchServiceListItem';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

const BranchServiceList = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.cart);

  const RemoveDataHandler = (id) => {
    dispatch(CartActions.removeItem(id));
  };

  const bookPageHandle = () => {
    if (state.items[0]) {
      dispatch(
        CartActions.getBranch({
          businessName: props.businessName,
          businessEmail: props.businessEmail,
        })
      );
      navigate(`/appointments/${props.branchId}`);
    } else {
      alert('cart is empty');
    }
  };

  return (
    <div className={classes.serviceSec}>
      <div className={classes.services}>
        <h3>Services</h3>
        <ul className={classes.serviceList}>
          {props.services.map((data, i) => (
            <BranchServiceListItem data={data} key={i} />
          ))}
        </ul>
      </div>

      <div className={classes.orders} id="appointmentBookSec">
        <h3>Order Summary</h3>
        <div className={classes.orderBox}>
          <div className={classes.orderHead}>
            <h4>Name</h4>
            <h4>Price</h4>
            <h4>&nbsp;</h4>
          </div>
          <div className={classes.orderBody}>
            {state.items.map((item) => (
              <div key={item.id} className={classes.orderList}>
                <p className={classes.orderName}>{item.name}</p>
                <p className={classes.orderCurrency}>
                  <span>&#8358; </span>
                  {item.price}
                </p>
                <button
                  onClick={RemoveDataHandler.bind(null, item.id)}
                  className={classes.orderDelBtn}
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              </div>
            ))}
          </div>
          <div className={classes.orderFoot}>
            {state.totalAmount === 0 ? (
              <p>Please add items to the cart.</p>
            ) : (
              <div>
                <p>Total</p>
                <p className={classes.orderTotalAmount}>
                  <span>&#8358; </span>
                  {state.totalAmount}
                </p>
              </div>
            )}
          </div>
        </div>
        <button className={classes.orderBookBtn} onClick={bookPageHandle}>
          Book
        </button>
      </div>
    </div>
  );
};
export default BranchServiceList;
