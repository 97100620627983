import classes from './Contact.module.css';
const Contact = () => {
  return (
    <div className={classes.contact}>
      <h1>Contact Us</h1>
      <div className={classes.contact_sub}>
        <div className={classes.contact_sub_details}>
          <h2>Connect us</h2>
          <p>
            <span
              className={`material-symbols-outlined ${classes.contact_icons}`}
            >
              mail
            </span>
            info@munnyfinds.com
          </p>
          {/* <p>
            <span
              className={`material-symbols-outlined ${classes.contact_icons}`}
            >
              call
            </span>
            +91 919191911
          </p>
          <p>
            <span
              className={`material-symbols-outlined ${classes.contact_icons}`}
            >
              location_on
            </span>
            ABC, Nigeria
          </p> */}
        </div>
        <img src="/assets/images/contact/contact1.jpg" alt="contact us"></img>
      </div>
    </div>
  );
};
export default Contact;
