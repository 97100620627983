const RatingsPopulater = (rating = 5, height, width) => {
  const filled = rating;
  const empty = 5 - filled;
  const ratePopulateData = [];

  for (let i = 0; i < filled; i++) {
    ratePopulateData.push(1);
  }

  for (let i = 0; i < empty; i++) {
    ratePopulateData.push(0);
  }

  const style1 = {
    display: 'flex',
  };

  const style2 = {
    height,
    width,
    marginRight: '0.5rem',
  };

  const stars = (
    <div style={style1}>
      {ratePopulateData.map((star, i) =>
        star === 1 ? (
          <img
            src="/assets/images/general/rated.png"
            alt="star"
            key={i}
            style={style2}
          ></img>
        ) : (
          <img
            src="/assets/images/general/unrated.png"
            alt="star"
            key={i}
            style={style2}
          ></img>
        )
      )}
    </div>
  );

  return stars;
};

export default RatingsPopulater;
