import classes from './BusinessAppointmentsItem.module.css';
import { useState } from 'react';
import RatingsPopulater from '../../utils/RatingsPopulater';
import { createPortal } from 'react-dom';
import { AppointmentsUrls, CustomerUrls } from '../../URLs/PublicUrls';

const BusinessAppointmentsItem = ({ cd, index }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [review, setReview] = useState(false);

  const detailViewHandle = () => {
    setViewDetails(!viewDetails);
  };

  const reiewHandle = () => {
    setReview(!review);
  };

  const cancelAppointmentHandle = async () => {
    setIsSubmitting(true);
    const data = {
      AppointmentId: cd.appointmentId,
      UserId: localStorage.getItem('userID'),
    };

    const response = await fetch(
      CustomerUrls.customerCancelAppointment +
        `?AppointmentId=${cd.appointmentId}&UserId=${localStorage.getItem(
          'userID'
        )}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      }
    );

    const resData = await response.json();
    alert(resData.message);
    setIsSubmitting(false);
    window.location.reload();
  };

  const completeAppointmentHandle = async () => {
    setIsSubmitting(true);

    const response = await fetch(
      AppointmentsUrls.completeAppointment + `${cd.appointmentId}`,
      {
        method: 'POST',
        body: JSON.stringify({ AppointmentId: cd.appointmentId }),
      }
    );
    const resData = await response.json();
    alert(resData.message);
    setIsSubmitting(false);
    window.location.reload();
  };

  const stopPrpagation = (e) => {
    e.stopPropagation();
  };

  const cancelTime = new Date(cd.endDateTime) > Date.now();

  return (
    <li className={classes.business_Appoint_Item}>
      <div className={classes.business_Apnt_Itm_head}>
        <p className={classes.b_A_I_head_serial}>#{index + 1}</p>
        <div className={classes.b_A_I_head_serial_div}>
          <p>Appointment No:</p>
          <h4>{cd.appointmentNo}</h4>
        </div>
        <div className={classes.b_A_I_head_serial_div}>
          <p>Appointment Date & Time:</p>
          <h4>
            {new Date(cd.appointmentDateTime).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </h4>
        </div>
        <div className={classes.b_A_I_head_serial_div}>
          <p>Service Provider:</p>
          <h4>{cd.businessName}</h4>
        </div>
      </div>
      <button
        className={classes.business_Apnt_Itm_btn}
        onClick={detailViewHandle}
      >
        View
      </button>
      {viewDetails && (
        <div className={classes.business_Apnt_Itm_details}>
          <div className={classes.business_Apnt_Itm_dtls_L}>
            <div className={classes.bsns_Apnt_Itm_dtls_L_div}>
              <p>Customer's name</p>
              <p>{cd.customerName}</p>
            </div>
            <div className={classes.bsns_Apnt_Itm_dtls_L_div}>
              <p>Employee's name</p>
              <p>{cd.employeeName}</p>
            </div>
            <div className={classes.bsns_Apnt_Itm_dtls_L_div}>
              <p>Estimated end time</p>
              <p>
                {new Date(cd.endDateTime).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </p>
            </div>
            <div className={classes.bsns_Apnt_Itm_dtls_L_div}>
              <p>Mode of payment</p>
              <p>{cd.modeOfPayment}</p>
            </div>
            <div className={classes.bsns_Apnt_Itm_dtls_L_div}>
              <p>Place of payment</p>
              <p>{cd.paymentPlace}</p>
            </div>
            <div className={classes.bsns_Apnt_Itm_dtls_L_div}>
              <p>Payment Status</p>
              <p>{cd.paymentStatus}</p>
            </div>
            <div className={classes.bsns_Apnt_Itm_dtls_L_div}>
              <p>Booking Status</p>
              <p>{cd.bookingStatus}</p>
            </div>
          </div>
          <div className={classes.business_Apnt_Itm_dtls_R}>
            <div className={classes.bsns_Apnt_Itm_dtls_R_div}>
              <p>Sub Total</p>
              <p>{cd.subTotal}</p>
            </div>
            <div className={classes.bsns_Apnt_Itm_dtls_R_div}>
              <p>Tax</p>
              <p>{cd.tax}</p>
            </div>
            <div className={classes.bsns_Apnt_Itm_dtls_R_div}>
              <p>Total</p>
              <p>{cd.total}</p>
            </div>
            {cd.paymentStatus === 'RECEIVED' ||
              (cd.bookingStatus !== 'CANCELLED' && cancelTime && (
                <button
                  className={classes.bsns_Apnt_Itm_dtls_R_div_BTN}
                  onClick={cancelAppointmentHandle}
                >
                  {isSubmitting ? 'Please wait...' : 'Cancel Appointment'}
                </button>
              ))}
            <button
              className={classes.bsns_Apnt_Itm_dtls_R_div_BTN}
              onClick={reiewHandle}
            >
              View Review
            </button>
            {cd.paymentStatus !== 'RECEIVED' && (
              <button
                className={classes.bsns_Apnt_Itm_dtls_R_div_BTN}
                onClick={completeAppointmentHandle}
              >
                {isSubmitting ? 'Please wait...' : 'Complete Appointment'}
              </button>
            )}
          </div>
        </div>
      )}
      {review &&
        createPortal(
          <div className={classes.bus_review_backdrop} onClick={reiewHandle}>
            <div className={classes.bus_review} onClick={stopPrpagation}>
              <img src="/assets/images/general/avatar.jpg" alt="customer"></img>
              <h4>{cd.name}</h4>
              <div className={classes.bus_review_ratings}>
                {RatingsPopulater(cd.rating, '1.7rem', '1.7rem')}
              </div>
              <div className={classes.bus_review_close} onClick={reiewHandle}>
                Close
              </div>
              <p>{cd.review}</p>
            </div>
          </div>,
          document.getElementById('popup')
        )}
    </li>
  );
};
export default BusinessAppointmentsItem;
