import classes from './Appointments.module.css';
import GetAppointment from './GetAppointment';
import OrderSummary from './OrderSummary';
import Thankyou from './Thankyou';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Appointments = (props) => {
  const [switchAvailable, setSwitchAvailable] = useState(true);
  const [apntAvailable, setApntAvailable] = useState(true);
  const [summaryAvailable, setSummaryAvailable] = useState(false);
  const [bookingConfirm, setbookingConfirm] = useState(false);
  const [error, setError] = useState(false);
  const state = useSelector((state) => state.cart);
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.items[0]) navigate(`/branches/${props.availablity.branchUrl}`);
  });

  const summarySwitcHandle = (data) => {
    if (
      state.appointmentDateTime &&
      state.employee.employeeName &&
      state.employee.employeeName
    ) {
      setApntAvailable(false);
      setSummaryAvailable(true);
      setError(false);
    } else if (!state.items[0]) {
      setError('Cart items not present. Go back to saloon.');
    } else {
      setError('Please select employee and timings.');
    }
  };
  const bookingHandle = () => {
    setbookingConfirm(true);
    setSwitchAvailable(false);
    setApntAvailable(false);
    setSwitchAvailable(false);
    setSummaryAvailable(false);
  };

  return (
    <div className={classes.appointments}>
      {switchAvailable && (
        <div className={classes.apnt_switches}>
          <div className={classes.apnt_swt_line}>&nbsp;</div>
          <button
            className={`${classes.apnt_switch} ${classes.apnt_switch_active}`}
          >
            &nbsp;<span>Appointment</span>
          </button>
          <button
            className={
              summaryAvailable
                ? `${classes.apnt_switch} ${classes.apnt_switch_active}`
                : classes.apnt_switch
            }
            onClick={summarySwitcHandle}
          >
            &nbsp;<span>Summary</span>
          </button>
        </div>
      )}
      {apntAvailable && (
        <GetAppointment
          onProceeding={summarySwitcHandle}
          availablity={props.availablity.schedule}
        />
      )}
      {summaryAvailable && <OrderSummary onBook={bookingHandle} />}
      {bookingConfirm && <Thankyou />}
      {error && <p className={classes.appnt_error}>{error}</p>}
    </div>
  );
};
export default Appointments;
