import { createSlice } from '@reduxjs/toolkit';

const CartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    totalAmount: 0,
    appointmentDateTime: undefined,
    employee: {
      employeeId: undefined,
      employeeName: undefined,
    },
    appointmentID: null,
    businessName: null,
    businessEmail: null,
  },
  reducers: {
    addItem(state, action) {
      const existingItemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      const existingItem = state.items[existingItemIndex];
      if (!existingItem) {
        state.items.push(action.payload);
        state.totalAmount = state.totalAmount + action.payload.price;
      }
    },

    removeItem(state, action) {
      const existingItemIndex = state.items.findIndex(
        (item) => item.id === action.payload
      );
      const existingItem = state.items[existingItemIndex];
      if (existingItem) {
        const filteredItems = state.items.filter(
          (item) => item.id !== existingItem.id
        );
        state.items = filteredItems;
        state.totalAmount = state.totalAmount - existingItem.price;
      }
    },

    resetCart(state) {
      state.items = [];
      state.totalAmount = 0;
      state.appointmentDateTime = undefined;
      state.employee.employeeId = undefined;
      state.employee.employeeName = undefined;
      state.appointmentID = null;
      state.businessName = null;
      state.businessEmail = null;
    },

    setAppointment(state, action) {
      if (action.payload.type === 'DATE-TIME') {
        state.appointmentDateTime = action.payload.dateTime;
      }

      if (action.payload.type === 'EMPLOYEE') {
        state.employee.employeeId = action.payload.employeeId;
        state.employee.employeeName = action.payload.employeeName;
      }

      if (action.payload.type === 'APPOINTMENT-ID') {
        state.appointmentID = action.payload.appointmentID;
      }
    },

    getBranch(state, action) {
      state.businessName = action.payload.businessName;
      state.businessEmail = action.payload.businessEmail;
    },
  },
});

export const CartActions = CartSlice.actions;
export default CartSlice;
