import classes from './HeroSearches.module.css';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const HeroSearches = (props) => {
  const navigate = useNavigate();

  // for services
  const [value, setValue] = useState('');
  const [suggetions, setSuggetions] = useState([]);
  const [suggetionsActive, setSuggetionsActive] = useState(false);
  const [suggetionIndex, setSuggetionIndex] = useState(0);

  // For Locations
  const [locaValue, setLocaValue] = useState('');
  const [locaSuggetions, setLocaSuggetions] = useState([]);
  const [locaSuggetionsActive, setLocaSuggetionsActive] = useState(false);
  const [locaSuggetionIndex, setLocaSuggetionIndex] = useState(0);

  // For Saloons
  const [saloonValue, setSaloonValue] = useState('');
  const [saloonSuggetions, setSaloonSuggetions] = useState([]);
  const [saloonSuggetionsActive, setSaloonSuggetionsActive] = useState(false);
  const [saloonSuggetionIndex, setSaloonSuggetionIndex] = useState(0);

  // All functions
  const onChangeFunc = (
    data,
    e,
    valState,
    setSuggetionState,
    setSuggActiveState,
    currentState
  ) => {
    const query = e.target.value.toLowerCase();
    valState(query);
    if (query.length > 1) {
      const filterSuggetions = data.filter(
        (suggetion) => suggetion.toLowerCase().indexOf(query) > -1
      );
      setSuggetionState(filterSuggetions);
      setSuggActiveState(true);
    } else {
      setSuggActiveState(false);
    }

    if (currentState === 'services') {
      setLocaValue('');
      setLocaSuggetions([]);
      setLocaSuggetionsActive(false);
      setLocaSuggetionIndex(0);
      setSaloonValue('');
      setSaloonSuggetions([]);
      setSaloonSuggetionsActive(false);
      setSaloonSuggetionIndex(0);
    }

    if (currentState === 'locations') {
      setValue('');
      setSuggetions([]);
      setSuggetionsActive(false);
      setSuggetionIndex(0);
      setSaloonValue('');
      setSaloonSuggetions([]);
      setSaloonSuggetionsActive(false);
      setSaloonSuggetionIndex(0);
    }

    if (currentState === 'saloons') {
      setValue('');
      setSuggetions([]);
      setSuggetionsActive(false);
      setSuggetionIndex(0);
      setLocaValue('');
      setLocaSuggetions([]);
      setLocaSuggetionsActive(false);
      setLocaSuggetionIndex(0);
    }
  };

  const suggetionClickFunc = (
    e,
    setSuggActiveState,
    setValueState,
    setSuggetionState
  ) => {
    setSuggActiveState(false);
    setValueState(e.target.innerText);
    setSuggetionState([]);
  };

  const onKeyPressFunc = (
    e,
    suggIndexState,
    suggState,
    setValueState,
    setSuggIndexState,
    setSuggActiveState
  ) => {
    if (e.keyCode === 40) {
      if (suggIndexState + 1 === suggState.length) return;
      setSuggIndexState(suggIndexState + 1);
    }

    if (e.keyCode === 38) {
      if (suggIndexState === 0) return;
      setSuggIndexState(suggIndexState - 1);
    }

    if (e.keyCode === 13) {
      setValueState(suggState[suggIndexState]);
      setSuggIndexState(0);
      setSuggActiveState(false);
    }
  };

  // For service events
  const serviceInputChangeHandle = (e) => {
    onChangeFunc(
      props.searches.services,
      e,
      setValue,
      setSuggetions,
      setSuggetionsActive,
      'services'
    );
  };
  const suggetionsOnclickHandle = (e) => {
    suggetionClickFunc(e, setSuggetionsActive, setValue, setSuggetions);
  };
  const serviceKeysHandle = (e) => {
    onKeyPressFunc(
      e,
      suggetionIndex,
      suggetions,
      setValue,
      setSuggetionIndex,
      setSuggetionsActive
    );
  };

  // For Location Events
  const locationInputChangeHandle = (e) => {
    onChangeFunc(
      props.searches.locations,
      e,
      setLocaValue,
      setLocaSuggetions,
      setLocaSuggetionsActive,
      'locations'
    );
  };
  const locaSuggetionsOnclickHandle = (e) => {
    suggetionClickFunc(
      e,
      setLocaSuggetionsActive,
      setLocaValue,
      setLocaSuggetions
    );
  };
  const locationKeysHandle = (e) => {
    onKeyPressFunc(
      e,
      locaSuggetionIndex,
      locaSuggetions,
      setLocaValue,
      setLocaSuggetionIndex,
      setLocaSuggetionsActive
    );
  };

  // For Saloon Events
  const saloonsData = props.searches.saloons.map((item) => item.name);
  const saloonInputChangeHandle = (e) => {
    onChangeFunc(
      saloonsData,
      e,
      setSaloonValue,
      setSaloonSuggetions,
      setSaloonSuggetionsActive,
      'saloons'
    );
  };

  const saloonSuggetionsOnclickHandle = (e) => {
    suggetionClickFunc(
      e,
      setSaloonSuggetionsActive,
      setSaloonValue,
      setSaloonSuggetions
    );
  };

  const saloonKeyHandle = (e) => {
    onKeyPressFunc(
      e,
      saloonSuggetionIndex,
      saloonSuggetions,
      setSaloonValue,
      setSaloonSuggetionIndex,
      setSaloonSuggetionsActive
    );
  };

  // All suggestion listings
  const listProvider = (
    suggActiveState,
    suggState,
    suggIndexState,
    clickHandler
  ) => {
    return (
      <ul className={classes.suggetionList}>
        {suggActiveState &&
          suggState.map((suggetion, i) => (
            <li
              className={`${classes.suggetionItem} ${
                i === suggIndexState ? `${classes.suggetionItemActive}` : ''
              }`}
              key={i}
              onClick={clickHandler}
            >
              {suggetion}
            </li>
          ))}
      </ul>
    );
  };

  const servicesSugg = listProvider(
    suggetionsActive,
    suggetions,
    suggetionIndex,
    suggetionsOnclickHandle
  );

  const locationsSugg = listProvider(
    locaSuggetionsActive,
    locaSuggetions,
    locaSuggetionIndex,
    locaSuggetionsOnclickHandle
  );

  const saloonSugg = listProvider(
    saloonSuggetionsActive,
    saloonSuggetions,
    saloonSuggetionIndex,
    saloonSuggetionsOnclickHandle
  );

  // search button
  const searchBtnHandle = () => {
    if (value) navigate(`services/ServiceName=${value}`);
    if (saloonValue) {
      let url;
      for (const saloon of props.searches.saloons) {
        if (saloon.name === saloonValue) {
          url = saloon.url;
          break;
        }
      }
      navigate(`branches/${url}`);
    }
    if (locaValue) navigate(`locations/Location=${locaValue}`);
  };

  return (
    <div className={classes.quick_serach}>
      <div className={classes.tabsBox}>
        <p className={classes.tabs}>Quick search</p>
      </div>
      <div className={classes.serviceInput}>
        <span className={`material-symbols-outlined ${classes.heroIcons}`}>
          content_cut
        </span>
        <input
          className={classes.input}
          type="text"
          placeholder="Select Services"
          value={value}
          onChange={serviceInputChangeHandle}
          onKeyDown={serviceKeysHandle}
        ></input>
        {suggetionsActive && servicesSugg}
      </div>
      <div className={classes.locationInput}>
        <span className={`material-symbols-outlined ${classes.heroIcons}`}>
          location_on
        </span>
        <input
          className={classes.input}
          type="text"
          placeholder="Select Location"
          onChange={locationInputChangeHandle}
          onKeyDown={locationKeysHandle}
          value={locaValue}
        ></input>
        {locaSuggetionsActive && locationsSugg}
      </div>
      <div className={classes.saloonInput}>
        <span className={`material-symbols-outlined ${classes.heroIcons}`}>
          storefront
        </span>
        <input
          className={classes.input}
          type="text"
          placeholder="Select Salons"
          onChange={saloonInputChangeHandle}
          onKeyDown={saloonKeyHandle}
          value={saloonValue}
        ></input>
        {saloonSuggetionsActive && saloonSugg}
      </div>
      <button className={classes.homeSearchBtn} onClick={searchBtnHandle}>
        Search
      </button>
    </div>
  );
};
export default HeroSearches;
