import { Link, Form } from 'react-router-dom';
import classes from './CustomerAccPageWrap.module.css';

const CustomerAccPageWrap = (props) => {
  return (
    <div className={classes.custAcc}>
      <div className={classes.custAcc_Left}>
        <ul>
          <li>
            <Link to="?user=profile">Profile</Link>
          </li>
          <li>
            <Link to="?user=appointments">Appointment</Link>
          </li>
          <li>
            <Link to="?user=change-password">Change Password</Link>
          </li>
          <li>
            <Form action="/logout" method="post">
              <button className={classes.custAcc_Left_btn}>Logout</button>
            </Form>
          </li>
        </ul>
      </div>
      <div className={classes.custAcc_Right}>{props.children}</div>
    </div>
  );
};
export default CustomerAccPageWrap;
