import classes from './WhyUs.module.css';
const WhyUs = () => {
  return (
    <div className={classes.WhyUs}>
      <h1>Why Us?</h1>
      <p className={classes.WhyUsBrief}>
      Choose Us for Unmatched Salon Excellence: The Reasons We Stand Above the Rest
      </p>
      <div className={classes.WhyUsGlance}>
        <div className={classes.WhyUsGlanceItem}>
          <img src="assets/images/general/offer.png" alt="offer"></img>
          <h5>Services</h5>
          <p>Connect with listed services</p>
        </div>
        <div className={classes.WhyUsGlanceItem}>
          <img src="assets/images/general/trust.png" alt="trust"></img>
          <h5>Trust Pay</h5>
          <p>100% Payment Protection</p>
        </div>
        <div className={classes.WhyUsGlanceItem}>
          <img src="assets/images/general/24x7.png" alt="24x7"></img>
          <h5>Book 24 X 7</h5>
          <p>Book anytime online at best prices</p>
        </div>
      </div>
      <p className={classes.WhyUsDescription}>
      We understand that your time is valuable, and your beauty needs are unique. MunnyFinds.com is dedicated to streamlining the salon booking process, putting you in control of your schedule and helping you look and feel fabulous. With our innovative platform, you'll experience unmatched convenience, reliability, and transparency in the world of salon booking.
      </p>
    </div>
  );
};

export default WhyUs;
