import classes from './CustomerProfile.module.css';
import { Form, useActionData, useNavigation } from 'react-router-dom';

const CustomerProfile = (props) => {
  const response = useActionData();
  const navigation = useNavigation();
  const updating = navigation.state === 'submitting';

  return (
    <div className={classes.custAcc_prof_details}>
      <h1>Profile</h1>
      <Form method="put" className={classes.custAcc_prof_form}>
        <div className={classes.custAcc_prof_Inputs}>
          <label htmlFor="custName">Name:</label>
          <input
            id="custName"
            type="text"
            name="custName"
            defaultValue={props.data.fullName || 'Your Name'}
          ></input>
        </div>
        <div className={classes.custAcc_prof_Inputs}>
          <label htmlFor="custEmail">Email:</label>
          <input
            id="custEmail"
            type="email"
            name="custEmail"
            defaultValue={props.data.email || 'Your Email'}
          ></input>
        </div>
        <div className={classes.custAcc_prof_Inputs}>
          <label htmlFor="custPhone">Phone Number:</label>
          <input
            id="custPhone"
            type="text"
            name="custPhone"
            defaultValue={props.data.phoneNumber || 'Your Phone number'}
          ></input>
        </div>
        <button className={classes.custAcc_prof_btn}>
          {updating ? 'Updating.....' : 'Update'}
        </button>
      </Form>
      {response && response.status === 'success' && (
        <p className={classes.updateProfMsgSuccess}>{response.message}</p>
      )}
      {response && response.status === 'failed' && (
        <p className={classes.updateProfMsgFail}>{response.message}</p>
      )}
      {response && response.errors && (
        <p
          className={classes.updateProfMsgFail}
        >{`Please fill up: ${response.errors.join(', ')}`}</p>
      )}
    </div>
  );
};
export default CustomerProfile;
