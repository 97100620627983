import classes from './GetAppointment.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { AppointmentsUrls } from '../../URLs/PublicUrls';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DataLoadingFunc from '../../utils/DataLoadingFunc';
import { CartActions } from '../../store/cart-slice';

const GetAppointment = (props) => {
  const [timings, setTimings] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [activeDayUserInput, setActiveDayUserInput] = useState(
    props.availablity[0]
  );

  const state = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const { branchID } = useParams();

  const currentDate = new Date(Date.now());
  const options = {
    month: 'long',
  };

  const availableDays = props.availablity.map((day) => {
    return {
      date: new Date(day).getDate(),
      day: new Date(day).toLocaleDateString('en-NG', { weekday: 'short' }),
      fullDate: day,
    };
  });

  const getDaysInMonth = (month, year) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      const itemIndex = availableDays.findIndex(
        (item) => item.date === date.getDate()
      );
      if (availableDays[itemIndex]) {
        days.push(availableDays[itemIndex]);
      } else {
        days.push({
          date: date.getDate(),
          day: new Date(date).toLocaleDateString('en-NG', {
            weekday: 'short',
          }),
          fullDate: null,
        });
      }
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const currMonth = currentDate.getMonth();
  const currYear = currentDate.getFullYear();
  const finalDates = getDaysInMonth(currMonth, currYear);

  useEffect(() => {
    const getEmployee = async () => {
      const data = await DataLoadingFunc(
        AppointmentsUrls.availableEmployees + `?BranchId=${branchID}`,
        'Failed loading employee list',
        500
      );
      const initialTime = await DataLoadingFunc(
        AppointmentsUrls.availableTimings +
          `?BranchId=${branchID}&AppointmentDate=${availableDays[0].fullDate}`,
        'Failed loading timings',
        500
      );

      if (typeof initialTime === []) {
        setTimings(initialTime);
      } else {
        setTimings(null);
      }

      setEmployees(data);
    };
    getEmployee();
  }, [branchID, availableDays]);

  const dateHandler = async (date) => {
    const data = await DataLoadingFunc(
      AppointmentsUrls.availableTimings +
        `?BranchId=${branchID}&AppointmentDate=${date}`,
      'Failed loading timings',
      500
    );
    setTimings(data);
    setActiveDayUserInput(date);
  };

  const proceedHandle = () => {
    props.onProceeding();
  };

  const employeeHandler = (e) => {
    const val = e.target.value;
    dispatch(
      CartActions.setAppointment({
        type: 'EMPLOYEE',
        employeeName: val.split('-$$$-')[0],
        employeeId: val.split('-$$$-')[1],
      })
    );
  };

  const timeSlotHandler = (e) => {
    const dateTime = e.target.value;
    dispatch(
      CartActions.setAppointment({ type: 'DATE-TIME', dateTime: dateTime })
    );
  };

  return (
    <div className={classes.apnt}>
      <div className={classes.apnt_head}>
        <p>Get Appointment</p>
      </div>
      <div className={classes.apnt_L}>
        <div className={classes.apnt_calender}>
          <div className={classes.apnt_calender_head}>
            <button>&#11164;</button>
            <p>{currentDate.toLocaleDateString('en-US', options)}</p>
            <button>&#11166;</button>
          </div>
          <div className={classes.apnt_calender_body}>
            {finalDates.map((item, i) => (
              <button
                key={i}
                onClick={dateHandler.bind(null, item.fullDate)}
                className={
                  item.fullDate
                    ? `${
                        item.fullDate === activeDayUserInput
                          ? classes.calenderSuperActive
                          : classes.calenderActive
                      }`
                    : classes.calenderInactive
                }
              >
                {item.date}
                <span>{item.day}</span>
              </button>
            ))}
          </div>
        </div>
        <select className={classes.apnt_Timings} onChange={timeSlotHandler}>
          <option>Select time-slot</option>
          {timings ? (
            timings.map((timing, i) => (
              <option key={i} value={timing}>
                {new Date(timing).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </option>
            ))
          ) : (
            <option>Closed</option>
          )}
        </select>
      </div>
      <div className={classes.apnt_R}>
        <div className={classes.apnt_R_top}>
          <ul>
            {state.items.map((item, i) => (
              <li className={classes.apnt_R_top_ListItem} key={i}>
                <p>{item.name}</p>
                <p>&#8358; {item.price}</p>
              </li>
            ))}
          </ul>
          <select
            className={classes.apnt_R_employee}
            onChange={employeeHandler}
          >
            <option>Select Employee</option>
            {employees.map((item) => (
              <option
                key={item.branchEmployeeId}
                value={`${item.name}-$$$-${item.branchEmployeeId}`}
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.apnt_R_bottom}>
          <div className={classes.apnt_R_totals}>
            <p>Total Amount</p>
            <p>&#8358; {state.totalAmount}</p>
          </div>
          <button className={classes.apnt_R_proceed} onClick={proceedHandle}>
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};
export default GetAppointment;
