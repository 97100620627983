import Branch from '../components/branchComponents/Branch';
import { defer, useLoaderData, Await } from 'react-router-dom';
import { BranchUrls } from '../URLs/PublicUrls';
import { Suspense } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import DataLoadingFunc from '../utils/DataLoadingFunc';

const BranchPage = () => {
  const { branch } = useLoaderData();
  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={branch}>
          {(loadedData) => <Branch branch={loadedData} />}
        </Await>
      </Suspense>
    </>
  );
};

const branchLoader = async (id) => {
  return await DataLoadingFunc(
    BranchUrls.branchByURL,
    'Could not load branches.',
    500,
    id
  );
};

export const loader = ({ params }) => {
  const id = params.id;
  return defer({
    branch: branchLoader(id),
  });
};

export default BranchPage;
