import { useEffect } from 'react';
import classes from './BlogItem.module.css';
import { seo } from '../../helpers/seo';
const BlogItem = (props) => {
  const { item } = props;
  useEffect(() => {
    seo({
      title: item.seoTitle,
      metaDescription: item.seoDescription,
      keywords: item.seoKeywords,
    });
  });
  return (
    <div className={classes.blog}>
      <div className={classes.author}>
        <img src="/assets/images/general/avatar.jpg" alt="author"></img>
        <p>{new Date(item.createdDate).toDateString()}</p>
      </div>
      <div className={classes.info}>
        <h1>{item.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: item.description }} />
      </div>
    </div>
  );
};
export default BlogItem;
