import classes from './BusinessChangePass.module.css';
import { Form, useActionData, useNavigation } from 'react-router-dom';

const BusinessChangePass = () => {
  const response = useActionData();
  const navigation = useNavigation();

  const updating = navigation.state === 'submitting';
  return (
    <div className={classes.business_pass}>
      <h1>Change Password</h1>
      <Form method="post" className={classes.business_pass_form}>
        <div className={classes.business_pass_Inputs}>
          <label htmlFor="businessOldPass">Old Password:</label>
          <input
            id="businessOldPass"
            type="password"
            name="businessOldPass"
            placeholder="Old Password"
          ></input>
        </div>
        <div className={classes.business_pass_Inputs}>
          <label htmlFor="businessNewPass">New Password:</label>
          <input
            id="businessNewPass"
            type="password"
            name="businessNewPass"
            placeholder="New Password"
          ></input>
        </div>
        <div className={classes.business_pass_Inputs}>
          <label htmlFor="businessConfirmPass">Confirm Password:</label>
          <input
            id="businessConfirmPass"
            type="password"
            name="businessConfirmPass"
            placeholder="Confirm Password"
          ></input>
        </div>
        <button className={classes.business_pass_btn}>
          {updating ? 'Please wait...' : 'Update'}
        </button>
      </Form>
      {response && response.status === 'success' && (
        <p className={classes.passChangeMsgSuccess}>{response.message}</p>
      )}
      {response && response.status === 'failed' && (
        <p className={classes.passChangeMsgFail}>{response.message}</p>
      )}
      {response && response.errors && (
        <p
          className={classes.passChangeMsgFail}
        >{`Please fill up: ${response.errors.join(', ')}`}</p>
      )}
    </div>
  );
};
export default BusinessChangePass;
