import classes from './Hero.module.css';

const Hero = () => {
  return (
    <>
      <img
        className={classes.img}
        src="assets/images/general/hero_img.jpg"
        alt="hero"
      ></img>
      <p className={classes.caption}>The Perfect Looks</p>
    </>
  );
};

export default Hero;
