import { useSearchParams } from 'react-router-dom';
import CustomerProfile from '../components/accounts/CustomerProfile';
import CustomerPassChange from '../components/accounts/CustomerPassChange';
import CustomerAppointments from '../components/accounts/CustomerAppointments';
import CustomerAccPageWrap from '../components/accounts/CustomerAccPageWrap';
import { AuthURLs } from '../URLs/AuthUrls';
import { AppointmentsUrls } from '../URLs/PublicUrls';
import { Await, defer, useLoaderData } from 'react-router-dom';
import DataLoadingFunc from '../utils/DataLoadingFunc';
import { CustomerUrls } from '../URLs/PublicUrls';
import { Suspense } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const CustomerAccountPage = () => {
  const { profileData, appointments } = useLoaderData();

  const [searchParams] = useSearchParams();
  const mode = searchParams.get('user');
  if (mode === 'profile')
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={profileData}>
          {(loadedData) => (
            <CustomerAccPageWrap>
              <CustomerProfile data={loadedData} />
            </CustomerAccPageWrap>
          )}
        </Await>
      </Suspense>
    );
  if (mode === 'appointments')
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Await resolve={appointments}>
          {(loadedData) => (
            <CustomerAccPageWrap>
              <CustomerAppointments data={loadedData} />
            </CustomerAccPageWrap>
          )}
        </Await>
      </Suspense>
    );
  if (mode === 'change-password')
    return (
      <CustomerAccPageWrap>
        <CustomerPassChange />
      </CustomerAccPageWrap>
    );
};
export default CustomerAccountPage;

export const action = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get('user');
  const inputData = await request.formData();
  const sendData = new FormData();
  let actionMessage;
  const userID = localStorage.getItem('userID');
  const errors = [];

  const dataFilter = (
    inputName,
    message,
    formAppendName,
    mannualData = undefined
  ) => {
    if (
      inputData.get(inputName) === '' ||
      inputData.get(inputName) === undefined ||
      (inputData.get(inputName) === null && message)
    ) {
      errors.push(message);
    } else {
      sendData.append(formAppendName, inputData.get(inputName) || mannualData);
    }
  };

  if (mode === 'change-password') {
    if (inputData.get('custConfirmPass') !== inputData.get('custNewPass')) {
      actionMessage = {
        status: 'failed',
        message: 'Reconfirm your password',
      };

      return actionMessage;
    }
    dataFilter('custOldPass', 'Old Password', 'oldPassword');
    dataFilter('custNewPass', 'New Password', 'newPassword');
    dataFilter('custConfirmPass', 'Confirm Password', 'confirmPassword');
    sendData.append('id', userID);

    if (errors[0]) {
      actionMessage = {
        status: 'failed',
        errors,
      };

      return actionMessage;
    }

    const response = await fetch(
      AuthURLs.customerChangePass +
        `?id=${userID}&oldPassword=${inputData.get(
          'custOldPass'
        )}&newPassword=${inputData.get('custNewPass')}`,
      {
        method: 'POST',
        body: sendData,
      }
    );

    const resData = await response.json();
    if (!response.ok) {
      actionMessage = {
        status: 'failed',
        message: resData.message,
      };

      return actionMessage;
    }

    actionMessage = {
      status: 'success',
      message: resData.message,
    };

    return actionMessage;
  }

  if (mode === 'profile') {
    dataFilter('custEmail', 'Email', 'email');
    dataFilter('custName', 'Name', 'name');
    dataFilter('custPhone', 'Phone Number', 'phoneno');
    sendData.append('id', userID);

    if (errors[0]) {
      actionMessage = {
        status: 'failed',
        errors,
      };

      return actionMessage;
    }

    const response = await fetch(
      AuthURLs.customerUpdateProfile +
        `?id=${userID}&email=${inputData.get('custEmail')}&name=${inputData.get(
          'custName'
        )}&phoneno=${inputData.get('custPhone')}`,
      {
        method: 'PUT',
        body: sendData,
      }
    );

    const resData = await response.json();
    if (!response.ok) {
      actionMessage = {
        status: 'failed',
        message: resData.message,
      };

      return actionMessage;
    }

    actionMessage = {
      status: 'success',
      message: resData.message,
    };

    return actionMessage;
  }
};

const profileLoader = async () => {
  return await DataLoadingFunc(
    CustomerUrls.customerProfile + `?UserId=${localStorage.getItem('userID')}`,
    'Failed loading customer Data',
    500
  );
};

const appointmentLoader = async () => {
  return await DataLoadingFunc(
    AppointmentsUrls.GetAppointmentByUser +
      `?CustomerId=${localStorage.getItem('userID')}`,
    'Failed loading customer Data',
    500
  );
};

export const loader = () => {
  return defer({
    profileData: profileLoader(),
    appointments: appointmentLoader(),
  });
};
