import BlogList from '../components/blogs/BlogList';
import { defer, Await, useLoaderData } from 'react-router-dom';
import DataLoadingFunc from '../utils/DataLoadingFunc';
import { BlogUrls } from '../URLs/PublicUrls';
import { Suspense } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import BlogItem from '../components/blogs/BlogItem';

const BlogsPage = () => {
  const { blogList, item } = useLoaderData();

  if (blogList) {
    return (
      <>
        <Suspense fallback={<LoadingSpinner />}>
          <Await resolve={blogList}>
            {(loadedData) => <BlogList list={loadedData} />}
          </Await>
        </Suspense>
      </>
    );
  }

  if (item) {
    return (
      <>
        <Suspense fallback={<LoadingSpinner />}>
          <Await resolve={item}>
            {(loadedData) => <BlogItem item={loadedData} />}
          </Await>
        </Suspense>
      </>
    );
  }
};
export default BlogsPage;

const blogListLoader = async () => {
  return await DataLoadingFunc(
    BlogUrls.allBlogs,
    'Could not load blog list',
    500
  );
};

const blogItemLoader = async (url) => {
  return await DataLoadingFunc(
    BlogUrls.blog,
    'Could not load blog item',
    500,
    url
  );
};

export const loader = () => {
  return defer({
    blogList: blogListLoader(),
  });
};

export const loaderItem = ({ params }) => {
  const url = params.url;
  return defer({
    item: blogItemLoader(url),
  });
};
