import { useState, useEffect } from "react";
import axios from "axios";
import classes from "./PartnerFees.module.css";
const PaymentStatus = (props) => {
  useEffect(() => {
    var arr = JSON.parse(localStorage.getItem("subscriptionItem"));
    readQueryString(arr.subscriptionTypeId);
  }, []);
  function readQueryString(subscriptionTypeId) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.get("status") === "success") {
      UpdatePaymentStatus(
        subscriptionTypeId,
        params.get("status"),
        params.get("txRef")
      );
    }
  }
  const applicationAPI = (
    url = "https://munnywebapi.azurewebsites.net/api/branchsubscription/"
  ) => {
    return {
      create: (newRecord) => axios.post(url + "insert", newRecord),
    };
  };
  function UpdatePaymentStatus(subscriptionTypeId, pgStatus, pgReference) {
    const formData = new FormData();
    formData.append("subscriptionTypeId", subscriptionTypeId);
    formData.append("branchId", localStorage.getItem("branchId"));
    formData.append("paymentStatus", pgStatus);
    formData.append("pGReference", pgReference);
    applicationAPI()
      .create(formData)
      .then((res) => {
        localStorage.removeItem("subscriptionItem");
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }
  return (
    <div className={classes.plans}>
      <h1>subscription updated</h1>
    </div>
  );
};
export default PaymentStatus;
