import classes from './BusinessDetails.module.css';
import { Form, useNavigation, useActionData } from 'react-router-dom';
import { useState } from 'react';

const BusinessDetails = (props) => {
  const [imageUpload, setImageUpload] = useState();
  const { data } = props;
  const responseData = useActionData();

  const ImageUploader = async (e) => {
    if (e.target.files[0]) {
      setImageUpload(URL.createObjectURL(e.target.files[0]));
    }
  };

  const navigation = useNavigation();
  const isUpdating = navigation.state === 'submitting';
  return (
    <div className={classes.busDetails}>
      <h1>Business Details</h1>
      <Form
        method="put"
        className={classes.bus_form_sec}
        encType="multipart/form-data"
      >
        <div className={classes.bus_forms}>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessType">Business Type</label>
            <select id="businessType" name="businessType">
              <option value="none">Please select</option>
              {data.businessTypes.map((type, i) => (
                <option
                  value={type.businessTypeId}
                  key={i}
                  selected={
                    data.businessTypeName === type.businessTypeName
                      ? 'selected'
                      : false
                  }
                >
                  {type.businessTypeName}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessName">Business Name</label>
            <input
              defaultValue={data.businessName}
              type="text"
              id="businessName"
              name="businessName"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="contactName">Contact Name</label>
            <input
              defaultValue={data.contactName}
              type="text"
              id="contactName"
              name="contactName"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessEmail">Email</label>
            <input
              defaultValue={data.email}
              type="email"
              id="businessEmail"
              name="businessEmail"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessLandline">Landline</label>
            <input
              defaultValue={data.landline}
              type="text"
              id="businessLandline"
              name="businessLandline"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessPhone">Phone number</label>
            <input
              defaultValue={data.mobileNo}
              type="text"
              id="businessPhone"
              name="businessPhone"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessLocation">Location</label>
            <input
              defaultValue={data.location}
              type="text"
              id="businessLocation"
              name="businessLocation"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessCity">City</label>
            <input
              defaultValue={data.city}
              type="text"
              id="businessCity"
              name="businessCity"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessCountry">Country</label>
            <input
              defaultValue={data.country}
              type="text"
              id="businessCountry"
              name="businessCountry"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessZip">Zip code</label>
            <input
              defaultValue={data.zipCode}
              type="text"
              id="businessZip"
              name="businessZip"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessLatitude">Latitude</label>
            <input
              defaultValue={data.latitude}
              type="text"
              id="businessLatitude"
              name="businessLatitude"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessLongitude">Longitude</label>
            <input
              defaultValue={data.longitude}
              type="text"
              id="businessLongitude"
              name="businessLongitude"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessCurrency">Currency</label>
            <input
              defaultValue={data.currency}
              type="text"
              id="businessCurrency"
              name="businessCurrency"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessStatus">Status</label>
            <input
              defaultValue={data.status}
              type="text"
              id="businessStatus"
              name="businessStatus"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessRating">Rating</label>
            <input
              defaultValue={data.rating}
              type="text"
              id="businessRating"
              name="businessRating"
            ></input>
          </div>
          <div className={classes.bus_inputDivs}>
            <label htmlFor="businessTotalRating">Total Ratings</label>
            <input
              defaultValue={data.totalRatings}
              type="text"
              id="businessTotalRating"
              name="businessTotalRating"
            ></input>
          </div>
          <div
            className={`${classes.bus_inputDivs} ${classes.addressInputDiv}`}
          >
            <label htmlFor="businessAddress">Address</label>
            <input
              defaultValue={data.address}
              type="text"
              id="businessAddress"
              name="businessAddress"
            ></input>
          </div>
          <div className={`${classes.bus_inputDivs} ${classes.aboutInputDiv}`}>
            <label htmlFor="businessAbout">About</label>
            <input
              defaultValue={data.about}
              type="text"
              id="businessAbout"
              name="businessAbout"
            ></input>
          </div>
          <div className={`${classes.bus_inputDivs} ${classes.mapInputDiv}`}>
            <label htmlFor="businessMap">Google Map URL</label>
            <input
              defaultValue={data.googleMapURL}
              type="text"
              id="businessMap"
              name="businessMap"
            ></input>
          </div>
        </div>
        <div className={classes.bus_DP}>
          <img src={imageUpload || data.imageSrc} alt="business display"></img>
          <h2>Business Image</h2>
          <div className={classes.bus_DP_inputDiv}>
            <label htmlFor="businessImage">Select Business Image</label>
            <input
              type="file"
              accept="image/*"
              id="businessImage"
              name="businessImage"
              onChange={ImageUploader}
            ></input>
          </div>
        </div>
        {responseData && (
          <p className={classes.responseMSG}>
            {responseData.errors ? (
              <div
                style={{ color: 'red' }}
              >{`Please fill up: ${responseData.errors.join(', ')}`}</div>
            ) : (
              <div
                style={{ color: responseData.status === 'failed' ? 'red' : '' }}
              >
                {responseData.message}
              </div>
            )}
          </p>
        )}
        <button className={classes.bus_Btn}>
          {isUpdating ? 'Please wait...' : 'Update business'}
        </button>
      </Form>
    </div>
  );
};
export default BusinessDetails;
