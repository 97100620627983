import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage, { loader as homeCategoryLoader } from './pages/HomePage';
import Root from './pages/Root';
import ErrorPage from './pages/ErrorPage';
import BranchesPage, { loader4category } from './pages/BranchesPage';
import { loader as allBranchesLoader } from './pages/BranchesPage';
import BranchPage, { loader as branchLoader } from './pages/BranchPage';
import About from './components/About';
import SubscriptionPage, {
  loader as subscriptionLoader,
} from './pages/SubscriptionPage';
import SearchPage, { loader as searchLoader } from './pages/SearchPage';
import AuthPage, {
  action as authActions,
  loader as authDataLoad,
} from './pages/AuthPage';
import AppointmentPage, {
  loader as availablityLoader,
} from './pages/AppointmentPage';
import BlogsPage, {
  loader as blogListLoader,
  loaderItem as blogItemLoader,
} from './pages/BlogsPage';
import CustomerAccountPage, {
  action as customerAccActions,
  loader as customerAccLoader,
} from './pages/CustomerAccountPage';
import { action as logoutAction } from './pages/Logout';
import BusinessAccountPage, {
  loader as businessAccLoader,
  action as businessAccActions,
} from './pages/BusinessAccountPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import UserConfirmaton from './components/forms/UserConfirmaton';
import ResetPassForm from './components/forms/ResetPassForm';
import FAQ, { loader as faqLoader } from './components/FAQ';
import Contact from './components/Contact';
import PaymentStatus from './components/PaymentStatus';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: homeCategoryLoader,
      },
      {
        path: 'branches',
        children: [
          {
            element: <BranchesPage />,
            index: true,
            loader: allBranchesLoader,
          },
          {
            path: ':id',
            element: <BranchPage />,
            loader: branchLoader,
          },
        ],
      },
      {
        path: 'category/:url',
        element: <BranchesPage />,
        loader: loader4category,
      },
      {
        path: 'services/:value',
        element: <SearchPage />,
        loader: searchLoader,
      },
      {
        path: 'locations/:value',
        element: <SearchPage />,
        loader: searchLoader,
      },
      {
        path: 'about-us',
        element: <About />,
      },
      {
        path: 'blogs',
        children: [
          {
            index: true,
            element: <BlogsPage />,
            loader: blogListLoader,
          },
          {
            path: ':url',
            element: <BlogsPage />,
            loader: blogItemLoader,
          },
        ],
      },
      {
        path: 'partner-fees',
        element: <SubscriptionPage />,
        loader: subscriptionLoader,
      },
      {
        path: 'PaymentStatus',
        element: <PaymentStatus />,
        loader: subscriptionLoader,
      },
      {
        path: 'auth',
        element: <AuthPage />,
        action: authActions,
        loader: authDataLoad,
      },
      {
        path: 'appointments/:branchID',
        element: <AppointmentPage />,
        loader: availablityLoader,
      },
      {
        path: 'customer-account',
        element: <CustomerAccountPage />,
        action: customerAccActions,
        loader: customerAccLoader,
      },
      {
        path: 'business-account',
        element: <BusinessAccountPage />,
        loader: businessAccLoader,
        action: businessAccActions,
      },
      {
        path: 'logout',
        action: logoutAction,
      },
      {
        path: 'reset-pass',
        element: <ResetPasswordPage />,
      },
      {
        path: 'confirmemail',
        element: <UserConfirmaton />,
      },
      {
        path: 'resetpassword',
        element: <ResetPassForm />,
      },
      {
        path: 'faq',
        element: <FAQ />,
        loader: faqLoader,
      },
      {
        path: 'contactus',
        element: <Contact />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
