import classes from './CustomerAppointmentsItem.module.css';
import { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import RatingsPopulater from '../../utils/RatingsPopulater';
import { CustomerUrls } from '../../URLs/PublicUrls';

const CustomerAppointmentsItem = ({ cd, index }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [review, setReview] = useState(false);
  const [starCount, setStarCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // refs
  const feedbackRef = useRef();

  const detailViewHandle = () => {
    setViewDetails(!viewDetails);
  };

  const reiewHandle = () => {
    setReview(!review);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const starCounter = (val) => {
    setStarCount(val);
  };

  const cancelAppointmentHandle = async () => {
    setIsSubmitting(true);
    const data = {
      AppointmentId: cd.appointmentId,
      UserId: localStorage.getItem('userID'),
    };

    const response = await fetch(
      CustomerUrls.customerCancelAppointment +
        `?AppointmentId=${cd.appointmentId}&UserId=${localStorage.getItem(
          'userID'
        )}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      }
    );

    const resData = await response.json();
    alert(resData.message);
    setIsSubmitting(false);
    window.location.reload();
  };

  const submitDataHandle = async () => {
    setIsSubmitting(true);
    const data = {
      AppointmentId: cd.appointmentId,
      Rating: starCount,
      Review: feedbackRef.current.value,
    };

    const response = await fetch(
      CustomerUrls.customerUpdateReview +
        `?AppointmentId=${cd.appointmentId}&Rating=${starCount}&Review=${feedbackRef.current.value}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      }
    );

    const resData = await response.json();
    alert(resData.message);
    setIsSubmitting(false);
  };

  return (
    <li className={classes.cust_Appoint_Item}>
      <div className={classes.cust_Apnt_Itm_head}>
        <p className={classes.c_A_I_head_serial}>#{index + 1}</p>
        <div className={classes.c_A_I_head_serial_div}>
          <p>Appointment no:</p>
          <h4>{cd.appointmentNo}</h4>
        </div>
        <div className={classes.c_A_I_head_serial_div}>
          <p>Appointment Date & Time:</p>
          <h4>
            {new Date(cd.appointmentDateTime).toLocaleString('en-US', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </h4>
        </div>
        <div className={classes.c_A_I_head_serial_div}>
          <p>Service Provider:</p>
          <h4>{cd.businessName}</h4>
        </div>
      </div>
      <button
        className={classes.custAcc_Apnt_Itm_btn}
        onClick={detailViewHandle}
      >
        View
      </button>
      {viewDetails && (
        <div className={classes.customer_Apnt_Itm_details}>
          <div className={classes.customer_Apnt_Itm_dtls_L}>
            <div className={classes.cust_Apnt_Itm_dtls_L_div}>
              <p>Customer's name</p>
              <p>{cd.customerName}</p>
            </div>
            <div className={classes.cust_Apnt_Itm_dtls_L_div}>
              <p>Employee's name</p>
              <p>{cd.employeeName}</p>
            </div>
            <div className={classes.cust_Apnt_Itm_dtls_L_div}>
              <p>Estimated end time</p>
              <p>
                {new Date(cd.endDateTime).toLocaleString('en-US', {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </p>
            </div>
            <div className={classes.cust_Apnt_Itm_dtls_L_div}>
              <p>Mode of payment</p>
              <p>{cd.modeOfPayment}</p>
            </div>
            <div className={classes.cust_Apnt_Itm_dtls_L_div}>
              <p>Booking Status</p>
              <p>{cd.bookingStatus}</p>
            </div>
            <div className={classes.cust_Apnt_Itm_dtls_L_div}>
              <p>Payment Status</p>
              <p>{cd.paymentStatus}</p>
            </div>
            <div className={classes.cust_Apnt_Itm_dtls_L_div}>
              <p>Place of payment</p>
              <p>{cd.paymentPlace}</p>
            </div>
          </div>
          <div className={classes.customer_Apnt_Itm_dtls_R}>
            <div className={classes.cust_Apnt_Itm_dtls_R_div}>
              <p>Sub Total</p>
              <p>{cd.subTotal}</p>
            </div>
            <div className={classes.cust_Apnt_Itm_dtls_R_div}>
              <p>Tax</p>
              <p>{cd.tax}</p>
            </div>
            <div className={classes.cust_Apnt_Itm_dtls_R_div}>
              <p>Total</p>
              <p>{cd.total}</p>
            </div>
            {cd.bookingStatus !== 'CANCELLED' &&
              cd.bookingStatus !==
                'BOOKED'(
                  <button
                    className={classes.cust_Apnt_Itm_dtls_R_div_BTN}
                    onClick={cancelAppointmentHandle}
                  >
                    {isSubmitting ? 'Please wait...' : 'Cancel Appointment'}
                  </button>
                )}
            {cd.paymentStatus === 'RECEIVED' ? (
              <button
                className={classes.cust_Apnt_Itm_dtls_R_div_BTN}
                onClick={reiewHandle}
              >
                Write Feedback
              </button>
            ) : (
              cd.bookingStatus !== 'CANCELLED' && (
                <div className={classes.cust_Apnt_Itm_dtls_R_div}>
                  <p>Review</p>
                  <p>{cd.review}</p>
                </div>
              )
            )}
          </div>
        </div>
      )}
      {review &&
        createPortal(
          <div className={classes.cust_feedbck_backdrop} onClick={reiewHandle}>
            <div className={classes.cust_feedbck} onClick={stopPropagation}>
              <h1>Feedback</h1>
              <p className={classes.cust_feedback_close} onClick={reiewHandle}>
                Close
              </p>

              <div className={classes.cust_feedbckDiv_stars}>
                <label>Rate us</label>
                <div className={classes.cust_feedback_starBox}>
                  <div className={classes.cust_fbdk_starPopulate}>
                    {RatingsPopulater(starCount, '2.2rem', '2.2rem')}
                  </div>
                  <div className={classes.cust_fbdk_starBtns}>
                    <button
                      onClick={starCounter.bind(null, 1)}
                      className={classes.cust_stars_btn}
                    >
                      &#9733;
                    </button>
                    <button
                      onClick={starCounter.bind(null, 2)}
                      className={classes.cust_stars_btn}
                    >
                      &#9733;
                    </button>
                    <button
                      onClick={starCounter.bind(null, 3)}
                      className={classes.cust_stars_btn}
                    >
                      &#9733;
                    </button>
                    <button
                      onClick={starCounter.bind(null, 4)}
                      className={classes.cust_stars_btn}
                    >
                      &#9733;
                    </button>
                    <button
                      onClick={starCounter.bind(null, 5)}
                      className={classes.cust_stars_btn}
                    >
                      &#9733;
                    </button>
                  </div>
                </div>
              </div>
              <div className={classes.cust_feedbckDiv}>
                <label htmlFor="custFeedbackMsg">Feedback</label>
                <textarea
                  type="text"
                  placeholder="Please leave your message"
                  id="custFeedbackMsg"
                  name="custFeedbackMsg"
                  rows="5"
                  ref={feedbackRef}
                ></textarea>
              </div>
              <button
                className={classes.cust_feedbckDiv_btn}
                onClick={submitDataHandle}
              >
                {isSubmitting ? 'Please wait...' : 'Submit'}
              </button>
            </div>
          </div>,
          document.getElementById('popup')
        )}
    </li>
  );
};
export default CustomerAppointmentsItem;
