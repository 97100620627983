import classes from './AllBranches.module.css';
import AllBranchItem from './AllBranchItem';
import { useState } from 'react';

const AllBranches = (props) => {
  const [suggetions, setSuggetions] = useState(props.branches);

  const onChangeHandle = (e) => {
    const query = e.target.value.toLowerCase();
    if (query.length > 1) {
      const filterSuggetions = props.branches.filter(
        (suggetion) => suggetion.businessName.toLowerCase().indexOf(query) > -1
      );
      setSuggetions(filterSuggetions);
    } else {
      setSuggetions(props.branches);
    }
  };

  console.log(suggetions);

  return (
    <div className={classes.allBranches}>
      <h1>Salons</h1>
      <div className={classes.branchSorting}>
        <label>Quick search</label>
        <input name="sorting" onChange={onChangeHandle}></input>
      </div>
      <ul className={classes.branchList}>
        {props.branches[0] ? (
          suggetions.map((branch, i) => (
            <AllBranchItem branch={branch} key={i} />
          ))
        ) : (
          <p style={{ fontSize: '2rem', textAlign: 'center' }}>
            No branch available!
          </p>
        )}
      </ul>
    </div>
  );
};
export default AllBranches;
