import classes from './BranchServiceListISubtem.module.css';
import { CartActions } from '../../store/cart-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const BranchServiceListISubtem = (props) => {
  const [btnText, setBtnText] = useState('+ Add');
  const dispatch = useDispatch();
  const { service } = props;

  const addingItemHandle = (item) => {
    dispatch(CartActions.addItem(item));
  };

  const state = useSelector((state) => state.cart.items);

  const checkAdded = () => {
    const existingItemIndex = state.findIndex(
      (elm) => elm.id === service.serviceId
    );
    const existingItem = state[existingItemIndex];
    if (existingItem) {
      setBtnText('Added');
    } else {
      setBtnText('+ Add');
    }
  };

  useEffect(() => {
    checkAdded();
  });

  return (
    <li className={classes.subServiceItem}>
      <p className={classes.subServiceTitle}>{service.serviceName}</p>
      <p className={classes.subServiceDuration}>
        {`${service.durationHours}hrs : ${service.durationMinutes}
          mins`}
      </p>
      <p className={classes.subServicePrice}>
        <span>&#8358; </span>
        {service.price}
      </p>
      <button
        onClick={addingItemHandle.bind(null, {
          id: service.serviceId,
          name: service.serviceName,
          price: service.price,
          hours: service.durationHours,
          min: service.durationMinutes,
        })}
        className={classes.subServiceBtn}
      >
        {btnText}
      </button>
    </li>
  );
};
export default BranchServiceListISubtem;
