import classes from './UserConfirmaton.module.css';
import { AuthURLs } from '../../URLs/AuthUrls';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerValidationActions } from '../../store/customerFormValidation';
import { LoginVisibilityActions } from '../../store/loginVisibility-slice';

const UserConfirmaton = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.customerValidation.signUp);
  const confirmToken = async () => {
    const searchParams = new URL(window.location.href).search;
    const urlConfirm = searchParams.split('&');
    const email = urlConfirm[0].replace('?email=', '');
    const token = urlConfirm[1].replace('token=', '');

    const data = {
      email: email,
      token: token,
    };

    const response = await fetch(AuthURLs.customerConfirmEmail, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const resData = await response.json();
    dispatch(
      CustomerValidationActions.registeration({
        status: resData.status,
        message: resData.message,
      })
    );
  };

  useEffect(() => {
    confirmToken();
  });

  const loginHandle = () => {
    dispatch(CustomerValidationActions.setRedirect());
    dispatch(LoginVisibilityActions.toggleVisibility());
  };

  const { message } = state.register;
  return (
    <div className={classes.confirmation}>
      <div className={classes.confirm_infoDiv}>
        <p className={classes.confirm_infoMsg}>
          {message ? message : 'Please Wait...'}
        </p>
        {message && (
          <p className={classes.confirm_login} onClick={loginHandle}>
            Please click here to Login
          </p>
        )}
      </div>
    </div>
  );
};
export default UserConfirmaton;
