import AllBranches from '../components/branchComponents/AllBranches';
import { BranchUrls, CategoriesUrls } from '../URLs/PublicUrls';
import { defer, useLoaderData, Await } from 'react-router-dom';
import { Suspense } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import DataLoadingFunc from '../utils/DataLoadingFunc';

const BranchesPage = () => {
  const { branches, category } = useLoaderData();

  if (category) {
    return (
      <>
        <Suspense fallback={<LoadingSpinner />}>
          <Await resolve={category}>
            {(loadedData) => <AllBranches branches={loadedData} />}
          </Await>
        </Suspense>
      </>
    );
  }

  if (branches) {
    return (
      <>
        <Suspense fallback={<LoadingSpinner />}>
          <Await resolve={branches}>
            {(loadedData) => <AllBranches branches={loadedData} />}
          </Await>
        </Suspense>
      </>
    );
  }
};

const allBranchesLoader = async () => {
  return await DataLoadingFunc(
    BranchUrls.branches,
    'Could not load branches.',
    500
  );
};

const categoryLoader = async (url) => {
  return await DataLoadingFunc(
    CategoriesUrls.categoryByUrl,
    'Could not load category.',
    500,
    url
  );
};

export const loader = () => {
  return defer({
    branches: allBranchesLoader(),
  });
};

export const loader4category = async ({ params }) => {
  const { url } = params;
  return defer({
    category: categoryLoader(url),
  });
};

export default BranchesPage;
