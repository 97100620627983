const domain = 'https://munnysalon.azurewebsites.net';

export const AuthURLs = {
  checkEmail: `${domain}/api/Auth/check-email?email=`,
  login: `${domain}/api/Auth/login`,
  signUp: `${domain}/api/Auth/register`,
  customerChangePass: `${domain}/api/Users/ChangePassword`,
  customerUpdateProfile: `${domain}/api/Users`,
  customerConfirmEmail: `${domain}/api/Users/ConfirmEmail`,
  sendEmail4ResetPass: `${domain}/api/Users/SendResetPassword`,
  resetPass: `${domain}/api/Users/ResetPassword`,
  registerBusiness: `${domain}/api/business/register-business`,
};
