import classes from './BusinessAccWrap.module.css';
import { Link, Form } from 'react-router-dom';

const BusinessAccWrap = (props) => {
  return (
    <div className={classes.businessAcc}>
      <div className={classes.businessAcc_Left}>
        <ul>
          {/* <li>
            <Link to="?user=profile">Profile</Link>
          </li> */}
          <li>
            <Link to="?user=business-details">Business details</Link>
          </li>
          <li>
            <Link to="?user=services">Services</Link>
          </li>
          <li>
            <Link to="?user=appointments">Appointments</Link>
          </li>
          <li>
            <Link to="?user=change-password">Change Password</Link>
          </li>
          <li>
            <Form action="/logout" method="post">
              <button className={classes.businessAcc_Left_btn}>Logout</button>
            </Form>
          </li>
        </ul>
      </div>
      <div className={classes.businessAcc_Right}>{props.children}</div>
    </div>
  );
};
export default BusinessAccWrap;
