import classes from './TopCategories.module.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

const TopCategories = (props) => {
  const navigate = useNavigate();
  const explorer = () => {
    navigate('/branches');
  };
  return (
    <div className={classes.category}>
      <h1>Popular Categories</h1>
      <p className={classes.categoryBrief}>
        Indulge in our Premier Salon Services: Discover Our Top Categories
      </p>
      <ul className={classes.categoryList}>
        {props.categories.map((category) => (
          <li className={classes.categoryListItem} key={category.categoryId}>
            <Link
              className={classes.categoryListItemAnchor}
              to={`/category/${category.categoryurl}`}
            >
              <img alt="category" src={category.imageSrc}></img>
              <p>{category.categoryName}</p>
            </Link>
          </li>
        ))}
      </ul>
      <button className={classes.categoryBtn} onClick={explorer}>
        Explore more<span>&#8594;</span>
      </button>
    </div>
  );
};

export default TopCategories;
