import classes from './About.module.css';
const About = () => {
  return (
    <div className={classes.about}>
      <div className={classes.aboutDescription}>
        <h2 className={classes.aboutDescriptionHead}>About Us</h2>
        <p className={classes.aboutDescriptionPara}>
          Welcome to MunnyFinds.com, your go-to online salon booking SaaS web
          application! We are thrilled to introduce you to a convenient and
          efficient way of connecting beauty enthusiasts with their favorite
          salons and professionals. Our platform is designed to revolutionize
          the salon booking experience, making it easier than ever to discover,
          book, and manage your beauty appointments.
        </p>
        <br />
        <h2 className={classes.aboutDescriptionHead}>Who We Are:</h2>
        <p className={classes.aboutDescriptionPara}>
          At MunnyFinds.com, we are a team of passionate individuals who
          understand the importance of self-care and looking and feeling your
          best. We believe that everyone deserves the opportunity to access
          top-notch salon services hassle-free. Our mission is to bridge the gap
          between customers and salons, creating a seamless and enjoyable
          booking process for both parties.
        </p>
        <br />
        <h2 className={classes.aboutDescriptionHead}>What We Offer:</h2>
        <p className={classes.aboutDescriptionPara}>
          <ul>
            <li>
              Easy Salon Discovery: Say goodbye to endless online searches and
              phone calls. With MunnyFinds.com, you can effortlessly browse
              through a vast network of salons, stylists, and beauty
              professionals in your area. We have an extensive database,
              ensuring you have a wide selection of options to choose from.
            </li>
            <li>
              Simple Online Booking: Gone are the days of waiting for the salon
              to open or being put on hold when calling to schedule an
              appointment. Our platform allows you to book your preferred beauty
              services with just a few clicks. Choose the date, time, and
              specific service you desire, all from the comfort of your own
              device.
            </li>
            <li>
              Real-Time Availability: No more guesswork. MunnyFinds.com provides
              real-time availability for all listed salons and professionals.
              This means you can see their open time slots and secure your
              appointment instantly, ensuring a seamless booking experience.
            </li>
            <li>
              User-Friendly Interface: We take pride in offering an intuitive
              and user-friendly interface. Our platform is designed to be
              accessible to all users, regardless of their tech-savviness.
              Whether you're a seasoned online booker or a first-timer, you'll
              find it easy to navigate and use our website.
            </li>
            <li>
              Customer Reviews and Ratings: Making informed decisions is
              essential when choosing beauty services. That's why we provide
              honest customer reviews and ratings for each salon and stylist.
              Read about others' experiences to help you find the perfect match
              for your needs.
            </li>
            <li>
              Secure Payment System: Your safety and privacy are paramount to
              us. Our secure payment system ensures that all transactions are
              protected, giving you peace of mind when booking your
              appointments.
            </li>
            <li>
              Mobile-Friendly: MunnyFinds.com is optimized for mobile devices,
              making it easy for you to book on the go. Whether you're using a
              smartphone or a tablet, you can access our platform seamlessly
              from any device.
            </li>
          </ul>
        </p>
        <br />
        <p className={classes.aboutDescriptionPara}>
          Join us today and discover a new era of salon booking simplicity at
          MunnyFinds.com. Book your next beauty appointment with ease and let us
          take care of the rest!
        </p>
      </div>
      <div className={classes.aboutSideInfo}>
        <img
          src="/assets/images/general/aboutus.jpg"
          alt="about"
          className={classes.aboutImg}
        ></img>
        <div className={classes.aboutLinks}>
          <h3>Quick links</h3>
          <ul>
            <li>
              <a href="/branches">Salons</a>
            </li>
            <li>
              <a href="/blogs">Blogs</a>
            </li>
            <li>
              <a href="/partner-fees">Partner Fees</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default About;
